<template>
  <div
    class="flex flex-col w-full h-full overflow-hidden dark:bg-gray-900/80 dark:text-white"
  >
    <div class="text-xl font-bold border-b dark:border-gray-800 p-7">
      {{ $t("dashboard.taskList") }}
    </div>
    <div
      v-if="deployments.length"
      class="flex flex-col items-center flex-1 w-full overflow-auto border-b scrollbar-visible dark:border-gray-800 max-h-96 lg:w-96 lg:max-h-full"
      ref="scrollContainer"
      @scroll="handleScroll"
    >
      <div class="flex flex-col w-full">
        <div
          v-for="(deployment, index) in deployments"
          :key="deployment.id"
          class="relative flex items-center px-4 py-4 space-x-4 border-b select-none dark:border-gray-800 sm:px-6 lg:px-8"
          :class="index == currentTask ? 'bg-gray-50 dark:bg-gray-800' : ''"
          @click="chooseTask(index)"
        >
          <div class="flex-auto min-w-0">
            <div class="flex items-center gap-x-3">
              <div
                class="flex-none p-1 rounded-full"
                :class="
                  deployment.mindmap
                    ? 'text-green-400 bg-green-400/10'
                    : 'text-gray-500 bg-gray-100/10'
                "
              >
                <div class="w-2 h-2 bg-current rounded-full" />
              </div>
              <h2
                class="text-sm font-semibold leading-6 text-black dark:text-white min-w-32"
              >
                <a :href="deployment.href" class="flex gap-x-2">
                  <span
                    class="truncate"
                    :class="
                      index == currentTask
                        ? 'text-indigo-600 dark:text-white'
                        : ''
                    "
                    >{{ deployment.pdf_name }}</span
                  >
                  <!-- <span class="text-gray-400">/</span>
                  <span class="whitespace-nowrap">model-1</span> -->
                  <span class="absolute inset-0" />
                </a>
              </h2>
            </div>
            <div
              class="mt-3 flex items-center gap-x-2.5 text-xs leading-5 text-gray-400"
            >
              <p class="truncate">{{ formatDate(deployment.update_time) }}</p>
              <svg
                viewBox="0 0 2 2"
                class="h-0.5 w-0.5 flex-none fill-gray-300"
              >
                <circle cx="1" cy="1" r="1" />
              </svg>
              <p class="whitespace-nowrap">
                {{
                  deployment.file_size
                    ? (deployment.file_size / (1024*1024)).toFixed(3)
                    : "- "
                }}MB
              </p>
            </div>
          </div>
          <ChevronRightIcon
            class="flex-none w-5 h-5 text-gray-400"
            :class="index == currentTask ? 'text-indigo-600' : ''"
            aria-hidden="true"
          />
        </div>
      </div>
      <div v-if="lastVisible == undefined" class="p-2 text-gray-400">
        {{ $t("dashboard.noMore") }}
      </div>
    </div>

    <div
      class="flex flex-col h-screen border-b min-w-96 lg:flex-row dark:border-gray-800"
      v-else
    >
      <div class="flex items-center justify-center w-full h-screen">
        <div class="text-center">
          <svg
            class="w-12 h-12 mx-auto text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              vector-effect="non-scaling-stroke"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
            />
          </svg>
          <h3
            class="mt-2 text-sm font-semibold text-gray-900 dark:text-gray-200"
          >
            {{ $t("dashboard.noHistory") }}
          </h3>
          <a
            href="/dashboard"
            class="mt-1 mb-6 text-sm text-blue-700 drop-shadow"
          >
            <!-- Go to Dashboard -->
            {{ $t("dashboard.toDashboard") }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const formatDate = function (timestamp) {
  var date = new Date(timestamp);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${month}/${day} ${hours}:${minutes}:${seconds}`;
};
import { db } from "../firebase/firebaseInit";

import { getCurrentUser } from "vuefire";
import {
  collection,
  query,
  where,
  orderBy,
  startAfter,
  startAt,
  limit,
  getDocs,
} from "firebase/firestore";

import {
  Bars3Icon,
  CalendarIcon,
  HomeIcon,
  XMarkIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/vue/24/outline";

export default {
  components: {
    Bars3Icon,
    CalendarIcon,
    HomeIcon,
    XMarkIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
  },
  data() {
    return {
      currentTask: 0,
      environments: {
        Preview: "text-gray-400 bg-gray-400/10 ring-gray-400/20",
        Production: "text-indigo-400 bg-indigo-400/10 ring-indigo-400/30",
      },
      statuses: {
        offline: "text-gray-500 bg-gray-100/10",
        online: "text-green-400 bg-green-400/10",
        error: "text-rose-400 bg-rose-400/10",
      },
      deployments: [],
      loading: false,
      lastVisible: {},
    };
  },
  methods: {
    formatDate,
    chooseTask(index) {
      this.currentTask = index;
      this.$emit("choose", this.deployments[index]);
    },
    handleScroll(e) {
      if (
        this.$refs.scrollContainer.scrollTop +
          this.$refs.scrollContainer.clientHeight >=
        this.$refs.scrollContainer.scrollHeight - 10
      ) {
        this.loadMore();
      }
    },
    async loadMore() {
      if (this.loading) {
        return;
      }
      if (this.lastVisible == undefined) {
        return;
      }
      this.loading = true;
      let q = query(
        collection(db, "history"),
        where("user_id", "==", this.user.uid),
        orderBy("update_time", "desc"),
        startAfter(this.lastVisible),
        limit(15)
      );
      let documentSnapshots = await getDocs(q);
      const lastVisible =
        documentSnapshots.docs[documentSnapshots.docs.length - 1];
      // console.log({ lastVisible });

      this.lastVisible = lastVisible;
      this.deployments = [
        ...this.deployments,
        ...documentSnapshots.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })),
      ];
      // console.log(this.deployments);
      this.loading = false;
    },
    async refresh() {
      this.deployments = [];
      this.lastVisible = {};
      const user = await getCurrentUser();
      this.user = user;
      await this.loadMore();
      // console.log(this.deployments);
      if (this.deployments.length > 0) {
        this.chooseTask(0);
      }else{
        this.$emit("choose", {});
      }
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>
