<template>
  <div class="flex flex-col w-full h-screen lg:flex-row">
    <div
      class="flex flex-col h-full duration-300 flex-[1_1_0%] border-r border-gray-200 dark:border-gray-800 dark:bg-gray-800 dark:text-white"
    >
      <HistoryList @choose="onChooseTask" ref="list"></HistoryList>
    </div>
    <div
      class="flex-[3_1_0%] p-2 dark:bg-gray-900 dark:text-white"
      v-if="!loading && mindmap_content"
    >
      <div
        class="flex flex-col w-full h-screen px-4 py-10 sm:px-6 lg:px-8 lg:py-6"
      >
        <div class="mb-8">
          <div class="sm:hidden">
            <label for="tabs" class="sr-only">Select a tab</label>
            <select
              id="tabs"
              name="tabs"
              class="block w-full py-2 pl-3 pr-10 text-base border-gray-300 rounded-md focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            >
              <option
                v-for="tab in tabs"
                :key="tab.name"
                :selected="tab.current"
              >
                {{ tab.name }}
              </option>
            </select>
          </div>
          <div class="hidden sm:block">
            <div class="border-b border-gray-200">
              <nav class="flex -mb-px space-x-8" aria-label="Tabs">
                <a
                  v-for="tab in tabs"
                  :key="tab.name"
                  @click="changeTab(tab)"
                  :class="[
                    tab.current
                      ? 'border-indigo-500 text-indigo-600 dark:border-white dark:text-white font-bold text-xl'
                      : 'border-transparent text-xl text-gray-500 hover:border-gray-300 hover:text-gray-700 dark:hover:text-white',
                    'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium',
                  ]"
                  :aria-current="tab.current ? 'page' : undefined"
                  >{{ tab.name }}</a
                >
              </nav>
            </div>
          </div>
        </div>
        <div class="flex-1 w-full overflow-scroll" v-if="tabs[0].current">
          <MindMap :content="mindmap_content" @delete="deleteCurrentTask" />
        </div>
        <div class="flex-1 w-full overflow-scroll" v-if="tabs[1].current">
          <MarkDown :content="mindmap_content" />
        </div>
      </div>
    </div>
    <div
      v-else-if="loading"
      class="flex-[3_1_0%] p-2 dark:bg-gray-900 dark:text-white"
    >
      <div class="flex items-center justify-center w-full h-screen">
        <div class="flex items-center space-x-2">
          <div
            class="w-8 h-8 border-4 border-blue-500 rounded-full spinner border-t-transparent"
          ></div>
          <span class="text-gray-700">Loading...</span>
        </div>
      </div>
    </div>
    <div v-else-if="current_id" class="flex-[3_1_0%] p-2 dark:bg-gray-900 dark:text-white">
      <div class="flex items-center justify-center w-full h-screen">
        <div class="text-center">
          <svg
            class="w-12 h-12 mx-auto text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              vector-effect="non-scaling-stroke"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
            />
          </svg>
          <h3 class="mt-2 text-sm font-semibold text-gray-900 dark:text-white">
            {{ $t("dashboard.analyzing") }}
          </h3>
          <p class="mt-1 mb-6 text-sm text-gray-500 dark:text-white">
            {{ $t("dashboard.analyzingTips") }}
          </p>
        </div>
      </div>
    </div>
    <Dialog
      :open="showDialog"
      :content="dialogContent"
      @confirm="deleteTask"
      @close="showDialog = false"
      type="error"
    ></Dialog>
  </div>
</template>

<script setup>
import MarkDown from "../components/result/markdown";
import HistoryList from "../components/HistoryList";
import MindMap from "@/components/result/mindmapPage";
import Dialog from "@/components/Dialog";

import { db } from "../firebase/firebaseInit";
import { doc, deleteDoc, getDoc } from "firebase/firestore";

import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();

let dialogContent = ref("");
let showDialog = ref(false);
const dialog = ref(null);

const loading = ref(true);

let tabs = ref([
  { name: "MindMap", href: "#", current: true },
  { name: "MarkDown", href: "#", current: false },
]);
let mindmap_content = ref("");
let current_id = ref("");
let list = ref(null);

const changeTab = (tab) => {
  tabs.value.forEach((t) => (t.current = t.name === tab.name));
};

const onChooseTask = (task) => {
  console.log(task.id);
  if (task.id) {
    changeTab(tabs.value[0]);
    mindmap_content.value = "";
    current_id.value = "";
    loading.value = true;
    setTimeout(() => {
      mindmap_content.value = task.mindmap;
      current_id.value = task.id;
      loading.value = false;
    });
  } else {
    loading.value = false;
  }
};

const deleteCurrentTask = async () => {
  showDialog.value = true;
  dialogContent.value = t("dashboard.deleteConfirm");
};

const deleteTask = async () => {
  let id = current_id.value;
  showDialog.value = false;
  loading.value = true;
  try {
    const taskRef = doc(db, "history", id);
    const taskSnap = await getDoc(taskRef);

    if (!taskSnap.exists()) {
      console.error(`Task with ID ${id} does not exist.`);
      alert(`Task with ID ${id} does not exist.`);
      return;
    }
    await deleteDoc(taskRef);
    list.value.refresh();
  } catch (error) {
    console.error("Error deleting task: ", error);
  }
};
</script>
