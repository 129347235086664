import { ref, watch, onMounted, nextTick } from 'vue'

export function useDarkMode() {
  const isDarkMode = ref(false)

  // 从localStorage获取用户偏好
  const getStoredTheme = () => localStorage.getItem('theme')

  // 保存主题到localStorage
  const storeTheme = (theme) => localStorage.setItem('theme', theme)

  // 检查用户的偏好
  const checkUserPreference = () => {
    const storedTheme = getStoredTheme()
    if (storedTheme) {
      isDarkMode.value = storedTheme === 'dark'
    } else if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      isDarkMode.value = true
    }
  }

  // 更新HTML类
  const updateHtmlClass = (dark) => {
    nextTick(() => {
      requestAnimationFrame(() => {
        if (dark) {
          document.documentElement.classList.add('dark')
        } else {
          document.documentElement.classList.remove('dark')
        }
        // 强制浏览器重新计算样式
        window.getComputedStyle(document.documentElement).getPropertyValue('color-scheme')
      })
    })
  }

  // 切换暗模式
  const toggleDarkMode = () => {
    isDarkMode.value = !isDarkMode.value
    storeTheme(isDarkMode.value ? 'dark' : 'light')
    updateHtmlClass(isDarkMode.value)
  }

  // 监听系统主题变化
  const listenForPreferenceChange = () => {
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
      if (!getStoredTheme()) {
        isDarkMode.value = e.matches
        updateHtmlClass(isDarkMode.value)
      }
    })
  }

  // 初始化
  onMounted(() => {
    checkUserPreference()
    updateHtmlClass(isDarkMode.value)
    listenForPreferenceChange()
  })

  // 当isDarkMode变化时更新HTML类
  watch(isDarkMode, (newValue) => {
    updateHtmlClass(newValue)
  })

  return {
    isDarkMode,
    toggleDarkMode
  }
}