import { createApp } from "vue";
import {
  VueFire,
  VueFireAuth,
  VueFireFirestoreOptionsAPI,
  VueFireDatabaseOptionsAPI,
} from "vuefire";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/css/app.css";
import BackToTop from "vue-backtotop";

import { createI18n } from "vue-i18n";
import zh from "./lang/zh";
import en from "./lang/en";

import { firebaseApp } from "./firebase/firebaseInit";

// console.log({firebaseApp})

let messages = {
  en,
  zh,
};
const savedLocale = localStorage.getItem("locale") || "en";
let i18n = createI18n({
  legacy: false,
  messages,
  locale: savedLocale,
});

const feather = require("feather-icons");
feather.replace();

// createApp(App).use(router).use(i18n).use(store).use(BackToTop).mount("#app");
createApp(App)
  .use(VueFire, {
    // imported above but could also just be created here
    firebaseApp,
    modules: [
      // we will see other modules later on
      VueFireAuth(),
      VueFireFirestoreOptionsAPI(),
      VueFireDatabaseOptionsAPI(),
    ],
  })
  .use(router)
  .use(i18n)
  .use(BackToTop)
  .mount("#app");

// console.log(localStorage.getItem("theme"));
// const appTheme = localStorage.getItem("theme")

// // Check what is the active theme and change theme when user clicks on the theme button in header.
// if (
//   appTheme === "dark" &&
//   document.querySelector("body").classList.contains("app-theme")
// ) {
//   document.querySelector("body").classList.add("bg-primary-dark");
// } else {
//   document.querySelector("body").classList.add("bg-secondary-light");
// }
