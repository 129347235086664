<template>
  <div>
    <div
      @click="openModal"
      class="flex items-center px-6 py-3 text-sm font-semibold leading-6 text-gray-900 dark:text-white gap-x-4 hover:bg-gray-50 dark:hover:bg-gray-500"
    >
      <div
        class="flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full"
      >
        <UsersIcon class="w-5 h-5 text-gray-600" aria-hidden="true" />
      </div>
      <span aria-hidden="true" @click="showUserConfig = true">{{
        user?.email
      }}</span>
    </div>
  </div>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal" class="relative z-50">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex items-center justify-center min-h-full p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl dark:bg-gray-800 rounded-2xl"
            >
              <div
                class="max-w-md p-4 mx-auto space-y-4 dark:bg-gray-800 dark:text-white"
              >
                <!-- Change Password -->
                <div
                  class="overflow-hidden border rounded-lg dark:border-gray-600"
                >
                  <div
                    @click="togglePasswordChange"
                    class="flex items-center justify-between w-full p-3 font-semibold text-left transition bg-gray-100 hover:bg-gray-200 dark:bg-gray-700 dark:hover:bg-gray-600"
                  >
                    {{ $t("userConfig.changePassword") }}
                    <svg
                      :class="{ 'rotate-180': showPasswordChange }"
                      class="w-5 h-5 transition-transform"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 9l-7 7-7-7"
                      ></path>
                    </svg>
                  </div>
                  <div
                    v-if="showPasswordChange"
                    class="p-3 space-y-3 dark:bg-gray-800"
                  >
                    <!-- <input
                      v-model="currentPassword"
                      type="password"
                      :placeholder="$t('currentPassword')"
                      class="w-full p-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    /> -->
                    <input
                      v-model="newPassword"
                      type="password"
                      :placeholder="$t('userConfig.newPassword')"
                      class="w-full p-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    />
                    <input
                      v-model="confirmPassword"
                      type="password"
                      :placeholder="$t('userConfig.confirmPassword')"
                      class="w-full p-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    />
                    <button
                      @click="changePassword"
                      class="w-full p-2 text-white transition bg-blue-500 rounded hover:bg-blue-600 dark:bg-blue-600 dark:hover:bg-blue-700"
                    >
                      {{ $t("userConfig.updatePassword") }}
                    </button>
                  </div>
                </div>

                <!-- Bind Google Account -->
                <div class="p-3 border rounded-lg dark:border-gray-600">
                  <h2 class="mb-2 font-semibold">
                    {{ $t("userConfig.bindGoogleAccount") }}
                  </h2>
                  <button
                    v-if="provider_google.length == 0"
                    @click="bindGoogleAccount"
                    class="flex items-center justify-center w-full p-2 text-white transition bg-red-500 rounded hover:bg-red-600 dark:bg-red-600 dark:hover:bg-red-700"
                  >
                    <svg
                      class="w-5 h-5 mr-2"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.24 10.285V14.4h6.806c-.275 1.765-2.056 5.174-6.806 5.174-4.095 0-7.439-3.389-7.439-7.574s3.345-7.574 7.439-7.574c2.33 0 3.891.989 4.785 1.849l3.254-3.138C18.189 1.186 15.479 0 12.24 0c-6.635 0-12 5.365-12 12s5.365 12 12 12c6.926 0 11.52-4.869 11.52-11.726 0-.788-.085-1.39-.189-1.989H12.24z"
                        fill="currentColor"
                      />
                    </svg>
                    {{ $t("userConfig.bindGoogle") }}
                  </button>
                  <div
                    v-else
                    class="flex items-center justify-center w-full p-2 transition border border-red-600 rounded dark:text-white dark:border-gray-600"
                  >
                    {{ $t("userConfig.bind") }}: {{ provider_google[0].email }}
                  </div>
                </div>

                <!-- Dark Mode Toggle -->
                <div
                  class="flex items-center justify-between p-3 border rounded-lg dark:border-gray-600"
                >
                  <span class="font-semibold">{{
                    $t("userConfig.darkMode")
                  }}</span>
                  <button
                    @click="toggleDarkMode"
                    class="relative flex items-center h-8 p-1 transition-colors duration-300 bg-gray-300 rounded-full w-14 focus:outline-none"
                    :class="{ 'bg-purple-600': isDarkMode }"
                  >
                    <div
                      class="w-6 h-6 transition-transform duration-300 transform bg-white rounded-full shadow-md"
                      :class="{ 'translate-x-6': isDarkMode }"
                    ></div>
                    <svg
                      class="absolute w-5 h-5 text-yellow-400 left-1.5"
                      :class="{ 'opacity-0': isDarkMode }"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
                      ></path>
                    </svg>
                    <svg
                      class="absolute w-5 h-5 text-indigo-200 right-1.5"
                      :class="{
                        'opacity-100': isDarkMode,
                        'opacity-0': !isDarkMode,
                      }"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
                      ></path>
                    </svg>
                  </button>
                </div>

                <!-- Language Selector -->
                <div class="p-3 border rounded-lg dark:border-gray-600">
                  <h2 class="mb-2 font-semibold">{{ $t("userConfig.language") }}</h2>
                  <select
                    v-model="selectedLanguage"
                    @change="changeLanguage"
                    class="w-full p-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  >
                    <option value="zh">中文</option>
                    <option value="en">English</option>
                  </select>
                </div>

                <!-- Logout -->
                <button
                  @click="logout"
                  class="w-full p-3 text-white transition bg-gray-500 rounded hover:bg-gray-600 dark:bg-gray-600 dark:hover:bg-gray-700"
                >
                  {{ $t("userConfig.logout") }}
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { ref, defineEmits } from "vue";
import { useCurrentUser } from "vuefire";
import {
  getAuth,
  signOut,
  updatePassword,
  GoogleAuthProvider,
  linkWithPopup,
} from "firebase/auth";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";

import { UsersIcon } from "@heroicons/vue/24/outline";

import { useRouter } from "vue-router";
let router = useRouter();

import { useDarkMode } from "../composables/useDarkMode";

const { isDarkMode, toggleDarkMode } = useDarkMode();

import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();

const user = useCurrentUser();
console.log(user.value);
const provider_google = ref([]);

console.log(provider_google.value);
const isOpen = ref(false);

const showPasswordChange = ref(false);

// const currentPassword = ref("");
const newPassword = ref("");
const confirmPassword = ref("");
const selectedLanguage = ref("");
selectedLanguage.value = locale.value;

const emit = defineEmits(["loaded"]);
function closeModal() {
  console.log("close!");
  isOpen.value = false;
}
function openModal() {
  provider_google.value = user.value.providerData.filter(
    (x) => x.providerId == "google.com"
  );
  isOpen.value = true;
  emit("loaded");
}

function logout() {
  let auth = getAuth();
  signOut(auth)
    .then(() => {
      router.push("/");
      // Sign-out successful.
    })
    .catch((error) => {
      console.log(error);
      // An error happened.
    });
}

function togglePasswordChange() {
  showPasswordChange.value = !showPasswordChange.value;
}

const changePassword = async () => {
  let auth = getAuth();
  if (newPassword.value !== confirmPassword.value) {
    alert(t("passwordMismatch"));
    return;
  }
  if (newPassword.value.length < 8) {
    alert(t("passwordTooShort"));
    return;
  }

  try {
    await updatePassword(auth.currentUser, newPassword.value);
    alert(t("passwordUpdateSuccess"));
    showPasswordChange.value = false;
    // currentPassword.value = "";
    newPassword.value = "";
    confirmPassword.value = "";
    await logout();
  } catch (error) {
    alert(t("passwordUpdateError"));
    await logout();
  }
};

const changeLanguage = () => {
  locale.value = selectedLanguage.value;
  localStorage.setItem("locale", locale.value);
};

const bindGoogleAccount = async () => {
  let auth = getAuth();
  const provider = new GoogleAuthProvider();
  try {
    await linkWithPopup(auth.currentUser, provider);
    alert(t("googleLinkSuccess"));
  } catch (error) {
    alert(t("googleLinkError"));
  }
};
</script>

<style scpoed>
/* Custom switch styling */
.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:checked + .slider:before {
  transform: translateX(24px);
}

.slider.round {
  border-radius: 24px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
