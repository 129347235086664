// config.js
// p5.js parameters
export const CANVAS_WIDTH = 1300 // 画板宽度
export const CANVAS_HEIGHT = 600 // 画板高度
export const NODE_RADIUS = 5
export const LINE_WEIGHT = 3
export const FRAME_RATE = 60

// layout internal parameters
export const LAYOUT_GAP = 10
export const LAYOUT_PAD = 30

// layout external parameters
export const MAX_WIDTH = 400 // 节点的最大宽度
export const LINE_HEIGHT = 20 // 文本每行的高度
export const MARGIN = 5 // 文本距离边框的margin
export const MIN_WIDTH = 100 // 节点的最小宽度
export const MIN_HEIGHT = 10 // 节点的最小高度

export const LayoutType = {
    DefaultLayout: 'DefaultLayout', // default layout is up-down layout
    LeftLayout: 'LeftLayout',
    RightLayout: 'RightLayout',
    StandardLayout: 'StandardLayout'
}

export const DarkTheme = "#0D1B2A" // 节点的最小高度 . 0D1B2A
export const LightTheme = "#ffffff" // 节点的最小高度

export const ColorPalette = {
    default: {
        background: ['#F4F1DE', '#E07A5F', '#3D405B', '#81B29A', '#F2CC8F', '#7e8b98'],
        text: ['#000', '#000', '#fff', '#000', '#000', '#000'],
        border: ['#F4F1DE', '#E07A5F', '#3D405B', '#81B29A', '#F2CC8F', '#7e8b98'],
        stroke: ['#F4F1DE', '#E07A5F', '#3D405B', '#81B29A', '#F2CC8F', '#7e8b98'],
    },
    flat: {
        background: ['#f9423a', '#486aff', '#fcc729', '#4d49be', '#38c9fa', '#7e8b98'],
        text: ['#000', '#000', '#000', '#fff', '#000', '#000'],
        border: ['#f9423a', '#486aff', '#fcc729', '#4d49be', '#38c9fa', '#7e8b98'],
        stroke: ['#f9423a', '#486aff', '#ffd426', '#4d49be', '#38c9fa', '#7e8b98'],
    },

    ocean: {
        background: ['#006d77', '#83c5be', '#edf6f9', '#ffddd2', '#e29578'],
        text: ['#fff', '#000', '#000', '#000', '#000', '#000'],
        border: ['#006d77', '#83c5be', '#edf6f9', '#ffddd2', '#e29578'],
        stroke: ['#006d77', '#83c5be', '#edf6f9', '#ffddd2', '#e29578'],
    },

    sunset: {
        background: ['#264653', '#2a9d8f', '#e9c46a', '#f4a261', '#e76f51'],
        text: ['#fff', '#000', '#000', '#000', '#000', '#000'],
        border: ['#264653', '#2a9d8f', '#e9c46a', '#f4a261', '#e76f51'],
        stroke: ['#264653', '#2a9d8f', '#e9c46a', '#f4a261', '#e76f51'],
    },
    flatter: {
        background: ['#fb645c', '#6a8cff', '#fff341', '#6f6bdf', '#5ad3fa', '#f8bf6f'],
        text: ['#000', '#000', '#000', '#000', '#000', '#000'],
        border: ['#fb645c', '#6a8cff', '#fff341', '#6f6bdf', '#5ad3fa', '#f8bf6f'],
        stroke: ['#fb645c', '#6a8cff', '#fff341', '#6f6bdf', '#5ad3fa', '#f8bf6f'],
    },
    pastel: {
        background: ['#fd867e', '#8caeff', '#f7f765', '#8f8dff', '#77dffe', '#fad9b3'],
        text: ['#000', '#000', '#000', '#000', '#000', '#000'],
        border: ['#fd867e', '#8caeff', '#f7f765', '#8f8dff', '#77dffe', '#fad9b3'],
        stroke: ['#fd867e', '#8caeff', '#f7f765', '#8f8dff', '#77dffe', '#fad9b3'],
    },
    black: {
        background: ['#fb645c', '#6a8cff', '#fcc729', '#6f6bdf', '#5ad3fa', '#f8bf6f', '#212529', '#ffd426'],
        text: ['#fff'],
        border: ['#000'],
        stroke: ['#fff'],
    },
    google: {
        background: ['#4285f4', '#ea4335', '#fbbc04', '#34a853', '#9AA0A6'],
        text: ['#000', '#fff', '#000', '#fff', '#fff', '#fff'],
        border: ['#4285f4', '#ea4335', '#fbbc04', '#34a853', '#9AA0A6'],
        stroke: ['#4285f4', '#ea4335', '#fbbc04', '#34a853', '#9AA0A6'],
    },
    google_light: {
        background: ['#D2E3FC', '#FAD2CF', '#FDE293', '#CEEAD6', '#F1F3F4 '],
        text: ['#000'],
        border: ['#D2E3FC', '#FAD2CF', '#FDE293', '#CEEAD6', '#F1F3F4 '],
        stroke: ['#D2E3FC', '#FAD2CF', '#FDE293', '#CEEAD6', '#F1F3F4 '],
    },
    magazine: {
        background: ['#023047', '#fb8500', '#ffb703', '#219ebc', '#ffb703'],
        text: ['#fff', '#000', '#333', '#333', '#000'],
        border: ['#023047', '#fb8500', '#ffb703', '#219ebc', '#ffb703'],
        stroke: ['#023047', '#fb8500', '#ffb703', '#219ebc', '#ffb703'],
    },
    cute: {
        background: ['#ffafcc', '#ffc8dd', '#cdb4db', '#bde0fe', '#a2d2ff', '#ffe5d9'],
        text: ['#000'],
        border: ['#ffafcc', '#ffc8dd', '#cdb4db', '#bde0fe', '#a2d2ff', '#ffe5d9'],
        stroke: ['#ffafcc', '#ffc8dd', '#cdb4db', '#bde0fe', '#a2d2ff', '#ffe5d9'],
    },
}