<template>
  <div class="flex flex-1 h-screen">
    <div
      class="flex flex-col items-start justify-center flex-1 px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24"
    >
      <div class="w-full max-w-sm mx-auto lg:w-96">
        <div class="flex items-center space-x-2">
          <svg
            class="w-8 h-8"
            style="color: #6366f1"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M13 10V3L4 14h7v7l9-11h-7z"
            ></path>
          </svg>
          <span class="text-xl font-bold" style="color: #6366f1"
            >PDF2MindMap</span
          >
        </div>

        <div class="mt-10">
          <div>
            <form
              class="space-y-6"
              @submit.prevent="registerUserWithEmailAndPassword"
            >
              <div>
                <label
                  for="email"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >{{ $t("login.email") }}</label
                >
                <div class="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    v-model="email"
                    autocomplete="email"
                    required=""
                    class="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div class="mt-8">
                <label
                  for="password"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >{{ $t("login.password") }}</label
                >
                <div class="flex mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    v-model="password"
                    autocomplete="current-password"
                    required=""
                    class="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  <!-- <button
                  type="submit"
                  class="flex w-48 ml-2 justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  get Code
                </button> -->
                </div>
              </div>

              <!-- <div class="flex items-center justify-between mt-8 mb-4">
                <div class="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    class="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-600"
                  />
                  <label
                    for="remember-me"
                    class="block ml-3 text-sm leading-6 text-gray-700"
                    >I Agree User xxxx</label
                  >
                </div>
              </div> -->

              <div>
                <button
                  type="submit"
                  class="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  {{ $t("login.signUp") }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="relative flex-1 hidden w-0 lg:block">
      <img
        class="absolute inset-0 object-cover w-full h-full"
        src="/background-auth.jpg"
        alt=""
      />
    </div>
    <Dialog
      :open="showDialog"
      :content="dialogContent"
      @confirm="showDialog = false"
      :showCancle="false"
      type="error"
    ></Dialog>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import Dialog from "@/components/Dialog";
import {
  signInWithRedirect,
  getRedirectResult,
  signInWithPopup,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import {
  useCollection,
  useFirestore,
  useCurrentUser,
  useFirebaseAuth,
} from "vuefire";
import { collection } from "firebase/firestore";

import { GoogleAuthProvider } from "firebase/auth";
const googleAuthProvider = new GoogleAuthProvider();
import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();

const auth = useFirebaseAuth();

const error = ref(null);

let email = ref("");
let password = ref("");
let dialogContent = ref("");
let showDialog = ref(false);
const dialog = ref(null);

const router = useRouter();
function registerUserWithEmailAndPassword() {
  if (email.value && password.value) {
    createUserWithEmailAndPassword(auth, email.value, password.value)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        console.log(user);
        router.push("/confirm-email");
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        if (errorCode === "auth/email-already-in-use") {
          console.log("already exist");
        }

        showDialog.value = true;
        dialogContent.value = errorCode;
        // ..
      });
  } else {
    showDialog.value = true;
    dialogContent.value = t("login.empty");
  }
}

// only on client side
onMounted(() => {});
</script>
