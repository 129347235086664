<template>
  <div>
    <div id="vditor" class="h-full" />
    <div
      v-if="loading"
      class="flex items-center justify-center w-full h-screen"
    >
      <div class="flex items-center space-x-2">
        <div
          class="w-8 h-8 border-4 border-blue-500 rounded-full spinner border-t-transparent"
        ></div>
        <span class="text-gray-700">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, defineProps } from "vue";
import Vditor from "vditor";
import "vditor/dist/index.css";

const props = defineProps({
  content: String,
});
const loading = ref(true);
onMounted(() => {
  new Vditor("vditor", {
    toolbar: [
      "moji",
      "headings",
      "bold",
      "italic",
      "strike",
      "|",
      "line",
      "quote",
      "list",
      "ordered-list",
      "check",
      "outdent",
      "indent",
      "code",
      "inline-code",
      "insert-after",
      "insert-before",
      "undo",
      "redo",
      "link",
      "table",
      "edit-mode",
      "both",
      "preview",
      "fullscreen",
      "outline",
      "export",
    ],
    cache: {
      enable: false,
    },
    value: props.content,
    height: "100%",
    after: () => {
      // console.log("markdown inited");
      loading.value = false;
    },
  });
});
</script>

<style scope>
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  animation: spin 1s linear infinite;
}
</style>
