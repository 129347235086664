<script>
import Hero from "@/components/Hero";
import Header from "@/components/Header";
import FAQs from "@/components/FAQs";
import Footer from "@/components/Footer";
import Testimonials from "@/components/Testimonials";
import Comments from "@/components/Comments";
import About from "@/components/About";
import Feature from "@/components/Feature";
export default {
  name: "Home",
  components: {
    Hero,
    FAQs,
    Header,
    Footer,
    Testimonials,
    Comments,
    About,
    Feature
  },
};
</script>

<template>
  <div class="flex flex-col items-stretch h-screen overflow-scroll">
    <!-- Banner -->
    <Header />
    <Hero />

    <Feature />
    <FAQs />
    <Testimonials />

    <!-- <About /> -->
    <Comments />

    <Footer />
    <!-- Load more projects button -->
  </div>
</template>

<style scoped></style>
