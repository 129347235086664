<template>
  <div
    class="relative w-full overflow-hidden bg-gray-100 rounded-lg shadow-md h-72 grid-background"
  >
    <div
      class="absolute inset-0 flex items-center justify-center transition-transform duration-1000"
      :class="{ 'transform translate-x-[-100%]': step > 0 }"
    >
      <div
        class="flex items-center justify-center w-32 h-40 bg-white border-2 border-gray-300 rounded"
      >
        <span class="text-2xl font-bold text-red-500">PDF</span>
      </div>
    </div>
    <div
      class="absolute inset-0 flex flex-col items-center justify-center"
      :class="{ 'opacity-100': step == 1 , 'opacity-0': step !== 1 }"
    >
      <div
        class="w-8 h-8 border-t-4 border-blue-500 rounded-full animate-spin"
      ></div>
      <div class="mt-4 font-bold text-gray-600">Uploading</div>
    </div>
    <div
      class="absolute inset-0 flex items-center justify-center p-4 transition-transform duration-1000"
      :class="{ 'transform translate-x-[100%]': step < 2 }"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 800 400"
        class=""
      >
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style="stop-color: #4f46e5; stop-opacity: 1" />
            <stop offset="100%" style="stop-color: #7c3aed; stop-opacity: 1" />
          </linearGradient>
        </defs>

        <!-- Main node -->
        <rect
          x="325"
          y="180"
          width="150"
          height="40"
          rx="20"
          fill="url(#gradient)"
          class="shadow-lg"
        />
        <text
          x="400"
          y="205"
          font-family="Arial, sans-serif"
          font-size="14"
          fill="white"
          text-anchor="middle"
        >
          Paper to Mindmap
        </text>

        <!-- Key Idea branch -->
        <path
          d="M475 200 Q525 150 575 150"
          stroke="#4F46E5"
          stroke-width="2"
          fill="none"
          stroke-dasharray="5,5"
        >
          <animate
            attributeName="stroke-dashoffset"
            from="0"
            to="20"
            dur="1s"
            repeatCount="indefinite"
          />
        </path>
        <rect
          x="575"
          y="130"
          width="100"
          height="40"
          rx="20"
          fill="#818CF8"
          class="shadow-md"
        />
        <text
          x="625"
          y="155"
          font-family="Arial, sans-serif"
          font-size="12"
          fill="white"
          text-anchor="middle"
        >
          Key Idea
        </text>

        <path
          d="M675 150 H700"
          stroke="#818CF8"
          stroke-width="2"
          fill="none"
          stroke-dasharray="5,5"
        >
          <animate
            attributeName="stroke-dashoffset"
            from="0"
            to="20"
            dur="1s"
            repeatCount="indefinite"
          />
        </path>
        <rect
          x="700"
          y="130"
          width="100"
          height="40"
          rx="20"
          fill="#818CF8"
          class="shadow-md"
        />
        <text
          x="750"
          y="155"
          font-family="Arial, sans-serif"
          font-size="12"
          fill="white"
          text-anchor="middle"
        >
          Abstract
        </text>

        <!-- Background branch -->
        <path
          d="M325 200 Q275 150 225 150"
          stroke="#10B981"
          stroke-width="2"
          fill="none"
          stroke-dasharray="5,5"
        >
          <animate
            attributeName="stroke-dashoffset"
            from="0"
            to="20"
            dur="1s"
            repeatCount="indefinite"
          />
        </path>
        <rect
          x="125"
          y="130"
          width="100"
          height="40"
          rx="20"
          fill="#34D399"
          class="shadow-md"
        />
        <text
          x="175"
          y="155"
          font-family="Arial, sans-serif"
          font-size="12"
          fill="white"
          text-anchor="middle"
        >
          Background
        </text>

        <path
          d="M125 150 H100"
          stroke="#34D399"
          stroke-width="2"
          fill="none"
          stroke-dasharray="5,5"
        >
          <animate
            attributeName="stroke-dashoffset"
            from="0"
            to="20"
            dur="1s"
            repeatCount="indefinite"
          />
        </path>
        <rect
          x="0"
          y="130"
          width="100"
          height="40"
          rx="20"
          fill="#34D399"
          class="shadow-md"
        />
        <text
          x="50"
          y="155"
          font-family="Arial, sans-serif"
          font-size="12"
          fill="white"
          text-anchor="middle"
        >
          Introduction
        </text>

        <!-- Method branch -->
        <path
          d="M475 200 Q525 250 575 250"
          stroke="#F59E0B"
          stroke-width="2"
          fill="none"
          stroke-dasharray="5,5"
        >
          <animate
            attributeName="stroke-dashoffset"
            from="0"
            to="20"
            dur="1s"
            repeatCount="indefinite"
          />
        </path>
        <rect
          x="575"
          y="230"
          width="100"
          height="40"
          rx="20"
          fill="#FBBF24"
          class="shadow-md"
        />
        <text
          x="625"
          y="255"
          font-family="Arial, sans-serif"
          font-size="12"
          fill="white"
          text-anchor="middle"
        >
          Method
        </text>

        <path
          d="M675 250 H700"
          stroke="#FBBF24"
          stroke-width="2"
          fill="none"
          stroke-dasharray="5,5"
        >
          <animate
            attributeName="stroke-dashoffset"
            from="0"
            to="20"
            dur="1s"
            repeatCount="indefinite"
          />
        </path>
        <rect
          x="700"
          y="230"
          width="100"
          height="40"
          rx="20"
          fill="#FBBF24"
          class="shadow-md"
        />
        <text
          x="750"
          y="255"
          font-family="Arial, sans-serif"
          font-size="12"
          fill="white"
          text-anchor="middle"
        >
          Framework
        </text>

        <!-- Related Works branch -->
        <path
          d="M325 200 Q275 250 225 250"
          stroke="#EC4899"
          stroke-width="2"
          fill="none"
          stroke-dasharray="5,5"
        >
          <animate
            attributeName="stroke-dashoffset"
            from="0"
            to="20"
            dur="1s"
            repeatCount="indefinite"
          />
        </path>
        <rect
          x="125"
          y="230"
          width="100"
          height="40"
          rx="20"
          fill="#F472B6"
          class="shadow-md"
        />
        <text
          x="175"
          y="255"
          font-family="Arial, sans-serif"
          font-size="12"
          fill="white"
          text-anchor="middle"
        >
          Related Works
        </text>
      </svg>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";

export default {
  setup() {
    const step = ref(0);
    let timer;

    const startAnimation = () => {
      timer = setInterval(() => {
        step.value = (step.value + 1) % 4;
      }, 1500);
    };

    onMounted(startAnimation);
    onUnmounted(() => clearInterval(timer));

    return { step };
  },
};
</script>

<style>
.grid-background {
  background-color: #f8f8f8;
  background-image:
    linear-gradient(to right, #e0e0e0 1px, transparent 1px),
    linear-gradient(to bottom, #e0e0e0 1px, transparent 1px);
  background-size: 40px 40px;
}
</style>
