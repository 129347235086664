import Dashboard from "../views/Dashboard.vue";

export default {
  home: {
    title: "PDF2MindMap",
    description: "transform pdf files to mind-map",
    button: "Get Started",
  },
  dialog: {
    notice: "Notice",
    cancel: "cancel",
    confirm: "confirm",
  },
  login: {
    title: "Sign in to your account",
    tips: "Not a member?",
    signUp: "Sign Up",
    email: "Email address",
    password: "Password",
    remember: "Remember me",
    forget: "Forgot password?",
    signIn: "Sign in",
    continue: "Or continue with",
    empty: "Email and password cannot be empty",
  },
  userConfig: {
    language: "language",
    bind: "bind",
    changePassword: "change Password",
    currentPassword: "current Password",
    newPassword: "new Password",
    confirmPassword: "confirm Password",
    updatePassword: "update Password",
    bindGoogleAccount: "bind Google Account",
    bindGoogle: "bind Google",
    darkMode: "dark Mode",
    logout: "log out",
  },
  dashboard: {
    dashboard: "dashboard",
    history: "history",
    empty: "No projects",
    emptyTips: "Get started by creating a new project.",
    addTask: "New Project",
    successed:
      "The record has been saved. Click on the History list on the left to view it",
    taskList: "Tasks",
    noMore: "no more",
    noHistory: "Empty",
    toDashboard: "Go to Dashboard",
    analyzing: "Task is being analyzed...",
    analyzingTips:
      "You may need to wait a few seconds to a few minutes, depending on the current server status.",
    deleteConfirm:
      "Are you sure you want to delete this task? This action cannot be undone.",
    fileSizeLimit: "File size exceeds 20MB",
  },
  faq: {
    faqs: "Frequently Asked Questions",
    description: "If you have more questions, you can send an email to:",
    description1: "We will reply to you after we see it.",
  },
  feature: {
    features: "Features",
    description:
      "Provides users with a comprehensive solution for converting and managing PDF to mind maps, allowing users to easily visualize PDF documents into easy-to-understand and operable mind map formats.",
  },
  hero: {
    title: "Convert Your PDF into Clear Mind Maps",
    description:
      "Simply upload your PDF file, and our intelligent tool will generate an intuitive mind map for you, helping you better understand and organize information.",
    try: "Try Now",
    learnMore: "Learn More",
  },
  testimonials: {
    line1:
      "In this era of information explosion, researchers face unprecedented challenges. Every day, a vast amount of new research pours in like a tidal wave. Capturing key information and distilling core ideas from the vast sea of literature has become a daunting task. It is based on this understanding that we developed this research assistance website.",
    line2:
      "Currently, our website allows users to upload PDF files and automatically generates an intuitive mind map. Through this tool, we hope to help everyone quickly grasp the core ideas of papers, improve reading efficiency, and inspire innovative ideas.",
    line3:
      "If I have seen further, it is by standing on the shoulders of giants.",
  },
  comments:"We have worked with thousands of amazing people"
};
