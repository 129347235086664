import { LINE_WEIGHT } from './config';

class DrawLine {
  constructor(p, layoutType) {
    this.p = p;
    this.layoutType = layoutType;
  }

  drawCurvedLine(startX, startY, endX, endY, startColor, endColor) {
    const midX = (startX + endX) / 2;
    const midY = (startY + endY) / 2;
    let controlX1, controlY1, controlX2, controlY2;

    switch (this.layoutType) {
      case 'LeftLayout':
      case 'RightLayout':
      case 'StandardLayout':
        controlX1 = midX;
        controlY1 = startY;
        controlX2 = midX;
        controlY2 = endY;
        break;
      case 'DefaultLayout':
      default:
        controlX1 = startX;
        controlY1 = midY;
        controlX2 = endX;
        controlY2 = midY;
        break;
    }

    this.p.push();
    this.p.noFill();

    // 创建渐变
    const ctx = this.p.drawingContext;
    const gradient = ctx.createLinearGradient(startX, startY, endX, endY);
    gradient.addColorStop(0, startColor);
    gradient.addColorStop(1, endColor);

    // 设置渐变为描边样式
    ctx.strokeStyle = gradient;

    // 设置线条宽度和样式
    ctx.lineWidth = LINE_WEIGHT;
    ctx.lineCap = 'round';
    ctx.lineJoin = 'round';

    this.p.push();
    this.p.noFill();

    // 绘制贝塞尔曲线
    ctx.beginPath();
    ctx.moveTo(startX, startY);
    ctx.bezierCurveTo(controlX1, controlY1, controlX2, controlY2, endX, endY);
    ctx.stroke();

    this.p.pop();
    this.p.pop();
  }

  draw(parentNode, childNode, parentColor, childColor) {
    let lineStartX, lineStartY, lineEndX, lineEndY;

    switch (this.layoutType) {
      case 'RightLayout':
        lineStartX = parentNode.x + parentNode.width;
        lineStartY = parentNode.y + parentNode.height / 2;
        lineEndX = childNode.x;
        lineEndY = childNode.y + childNode.height / 2;
        break;
      case 'LeftLayout':
        lineStartX = parentNode.x;
        lineStartY = parentNode.y + parentNode.height / 2;
        lineEndX = childNode.x + childNode.width;
        lineEndY = childNode.y + childNode.height / 2;
        break;
      case 'StandardLayout':
        if (childNode.position === 'left') {
          lineStartX = parentNode.x;
          lineStartY = parentNode.y + parentNode.height / 2;
          lineEndX = childNode.x + childNode.width;
          lineEndY = childNode.y + childNode.height / 2;
        } else {
          lineStartX = parentNode.x + parentNode.width;
          lineStartY = parentNode.y + parentNode.height / 2;
          lineEndX = childNode.x;
          lineEndY = childNode.y + childNode.height / 2;
        }
        break;
      case 'DefaultLayout':
      default:
        lineStartX = parentNode.x + parentNode.width / 2;
        lineStartY = parentNode.y + parentNode.height;
        lineEndX = childNode.x + childNode.width / 2;
        lineEndY = childNode.y;
        break;
    }

    const startColor = parentColor ? parentColor.stroke : '#000000';
    const endColor = childColor ? childColor.stroke : startColor;

    this.drawCurvedLine(lineStartX, lineStartY, lineEndX, lineEndY, startColor, endColor);
  }
}

export default DrawLine;