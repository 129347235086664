<template>
  <div class="relative bg-white dark:bg-gray-900 isolate">
    <div class="px-6 py-16 mx-auto max-w-7xl sm:py-24 lg:px-8">
      <h2
        class="text-2xl font-bold leading-10 tracking-tight text-gray-900 dark:text-white"
      >
        {{ $t("faq.faqs") }}
      </h2>
      <p
        class="max-w-2xl mt-6 text-base leading-7 text-gray-600 dark:text-gray-300"
      >
        {{ $t("faq.description") }}
        <a class="font-semibold text-indigo-600 hover:text-indigo-500"
          >pdf2mindmap@gmail.com</a
        >
        {{ $t("faq.description1") }}
      </p>
      <div class="mt-20">
        <dl
          class="space-y-16 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-16 sm:space-y-0 lg:gap-x-10"
        >
          <div v-for="faq in faqs" :key="faq.id">
            <dt
              class="text-base font-semibold leading-7 text-gray-900 dark:text-white"
            >
              {{ faq.question }}
            </dt>
            <dd
              class="mt-2 text-base leading-7 text-gray-600 dark:text-gray-300"
            >
              {{ faq.answer }}
            </dd>
          </div>
        </dl>
      </div>
    </div>
    <div
      class="absolute top-0 -translate-x-1/2 left-1/2 -z-10 blur-3xl xl:-top-6"
      aria-hidden="true"
    >
      <div
        class="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
        style="
          clip-path: polygon(
            74.1% 44.1%,
            100% 61.6%,
            97.5% 26.9%,
            85.5% 0.1%,
            80.7% 2%,
            72.5% 32.5%,
            60.2% 62.4%,
            52.4% 68.1%,
            47.5% 58.3%,
            45.2% 34.5%,
            27.5% 76.7%,
            0.1% 64.9%,
            17.9% 100%,
            27.6% 76.8%,
            76.1% 97.7%,
            74.1% 44.1%
          );
        "
      />
    </div>
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();
import { ref, watch } from "vue";

let faqs = ref([]);
function changeI18n() {
  if (locale.value == "en") {
    faqs.value = [
      {
        id: 1,
        question: "What is the PDF to Mind Map service?",
        answer:
          "Our service can automatically convert your PDF documents into intuitive mind map formats, helping you better understand and organize the content of the documents.",
      },
      {
        id: 2,
        question: "What is the maximum size of the PDF file I can upload?",
        answer:
          "Currently, we support uploading PDF files up to 20MB. If your file exceeds this size, please consider splitting it into smaller parts.",
      },
      {
        id: 3,
        question: "How long does the conversion process take?",
        answer:
          "The conversion time depends on the size and complexity of the PDF. Generally, most files can be converted within a few minutes. You can check the task progress in 'History'.",
      },
      {
        id: 4,
        question: "Can I customize the generated mind map?",
        answer:
          "Yes, you can adjust the Markdown content to modify the mind map. Our mind map is actually automatically generated based on Markdown.",
      },
      {
        id: 5,
        question:
          "How long will my PDF files and generated mind maps be saved?",
        answer:
          "To protect your privacy, we will automatically delete your PDF files 30 days after the conversion is complete. The generated mind maps will be saved for 90 days, and you can download or delete them at any time.",
      },
      {
        id: 6,
        question: "What mind map export formats are supported?",
        answer: "We currently only support the PNG format.",
      },
      {
        id: 7,
        question: "What should I do if the conversion fails?",
        answer:
          "If the conversion fails, the system will automatically notify you. You can try re-uploading the file or contact our customer support for help.",
      },
      {
        id: 8,
        question: "Can I run multiple conversion tasks simultaneously?",
        answer:
          "Yes, you can submit multiple conversion tasks simultaneously. The progress of each task can be viewed individually in 'History'.",
      },
      {
        id: 9,
        question: "Is this service free?",
        answer: "It is currently completely free!",
      },
      {
        id: 10,
        question: "How do you ensure the security of my files?",
        answer:
          "We use advanced encryption technology to protect your files and promptly delete the original PDFs after processing. We will never share your file content with third parties.",
      },
    ];
  } else if (locale.value == "zh") {
    faqs.value = [
      {
        id: 1,
        question: "什么是PDF转脑图服务？",
        answer:
          "我们的服务可以将您的PDF文档自动转换为直观的脑图格式，帮助您更好地理解和组织文档内容。",
      },
      {
        id: 2,
        question: "我可以上传多大的PDF文件？",
        answer:
          "目前，我们支持上传最大20MB的PDF文件。如果您的文件超过这个大小，请考虑将其分割成较小的部分。",
      },
      {
        id: 3,
        question: "转换过程需要多长时间？",
        answer:
          "转换时间取决于PDF的大小和复杂度。一般情况下，大多数文件可以在几分钟内完成转换。您可以在'历史查询'中查看任务进度。",
      },
      {
        id: 4,
        question: "我可以自定义生成的脑图吗？",
        answer:
          "是的，您可以调整Markdwon内容来修改Mindmap，我们的Mindmap其实是基于Markdown自动生成的。",
      },
      {
        id: 5,
        question: "我的PDF文件和生成的脑图会被保存多长时间？",
        answer:
          "为了保护您的隐私，我们会在转换完成后30天自动删除您的PDF文件。生成的脑图将保存90天，您可以随时下载或删除。",
      },
      {
        id: 6,
        question: "支持哪些脑图导出格式？",
        answer: "我们目前只支持PNG格式。",
      },
      {
        id: 7,
        question: "如果转换失败了怎么办？",
        answer:
          "如果转换失败，系统会自动通知您。您可以尝试重新上传文件或联系我们的客户支持寻求帮助。",
      },
      {
        id: 8,
        question: "我可以同时进行多个转换任务吗？",
        answer:
          "是的，您可以同时提交多个转换任务。每个任务的进度可以在'历史查询'中单独查看。",
      },
      {
        id: 9,
        question: "这项服务是免费的吗？",
        answer: "目前是完全免费的！",
      },
      {
        id: 10,
        question: "如何确保我的文件安全？",
        answer:
          "我们使用高级加密技术保护您的文件，并在处理完成后及时删除原始PDF。我们绝不会与第三方分享您的文件内容。",
      },
    ];
  }
}

changeI18n();

watch(locale, () => {
  changeI18n();
});
</script>
