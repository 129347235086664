<template>
  <!-- <div> -->
  <div class="w-full h-full container_page">
    <div
      class="flex items-center p-4 space-x-4 overflow-auto bg-gray-100 rounded-t-lg shadow-md dark:bg-gray-800">
      <div class="flex space-x-2">
        <button
          v-for="(layout, index) in layouts"
          :key="layout"
          @click="setCurrentLayout(layout)"
          class="p-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:bg-gray-700 dark:text-gray-200 dark:border-gray-600 dark:hover:bg-gray-600 dark:focus:ring-offset-gray-800"
          :title="`${layout} Layout`">
          <svg class="w-6 h-6" viewBox="0 0 24 24" fill="currentColor">
            <g v-if="index === 0">
              <rect x="2" y="4" width="9" height="16" />
              <rect x="13" y="4" width="9" height="16" />
            </g>
            <g v-else-if="index === 1">
              <rect x="2" y="4" width="6" height="16" />
              <rect x="10" y="4" width="12" height="16" />
            </g>
            <g v-else-if="index === 2">
              <rect x="2" y="4" width="12" height="16" />
              <rect x="16" y="4" width="6" height="16" />
            </g>
            <g v-else-if="index === 3">
              <rect x="2" y="2" width="20" height="9" />
              <rect x="2" y="13" width="20" height="9" />
            </g>
          </svg>
        </button>
      </div>

      <select
        v-model="currentColorScheme"
        class="block py-2 pl-3 pr-10 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:bg-gray-700 dark:text-gray-200 dark:border-gray-600">
        <option v-for="scheme in colorSchemes" :key="scheme" :value="scheme">
          {{ capitalizeFirstLetter(scheme) }}
        </option>
      </select>

      <button
        @click="handleSave"
        class="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:bg-indigo-500 dark:hover:bg-indigo-600 dark:focus:ring-offset-gray-800 shrink-0">
        Save PNG
      </button>
      <button
        v-if="showDelete"
        @click="handleDelete"
        class="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-offset-gray-800">
        Delete
      </button>
    </div>
    <div ref="mapContainer" class="flex flex-1">
      <MindMap
        class="shrink"
        v-if="treeData && treeData.content && canvasWidth"
        :treeData="treeData"
        :layoutType="currentLayout"
        :colorScheme="currentColorScheme"
        :canvasWidth="canvasWidth"
        :canvasHeight="canvasHeight"
        ref="mindMapRef"
        :theme="currentTheme" />
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import {
  ref,
  computed,
  onMounted,
  nextTick,
  defineProps,
  watch,
  onUnmounted,
} from "vue";
import MindMap from "./mindmap/MindMap.vue";
import { LayoutType, ColorPalette } from "./mindmap/config";
import { useDarkMode } from "@/composables/useDarkMode";
import { Transformer } from "markmap-lib/no-plugins";
import { pluginFrontmatter } from "markmap-lib/plugins";

export default {
  name: "App",
  components: {
    MindMap,
  },
  props: {
    content: {
      type: String,
      default: "",
    },
    showDelete: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const { isDarkMode, toggleDarkMode } = useDarkMode();
    const mapContainer = ref();
    const canvasWidth = ref(0);
    const canvasHeight = ref(0);
    const currentLayout = ref(LayoutType.StandardLayout);
    const currentColorScheme = ref("default");
    // console.log("content:", props.content);
    const markdownText = ref(props.content);
    const treeData = ref(null);
    const mindMapRef = ref(null);
    let observer = null;

    const layouts = [
      LayoutType.StandardLayout,
      LayoutType.LeftLayout,
      LayoutType.RightLayout,
      LayoutType.DefaultLayout,
    ];

    const colorSchemes = computed(() => Object.keys(ColorPalette));
    const currentTheme = computed(() => (isDarkMode.value ? "dark" : "light"));

    const parseMarkdownToTreeData = (markdownText) => {
      const transformer = new Transformer([pluginFrontmatter]);
      const { root } = transformer.transform(markdownText);
      return root;
    };

    const handleConvert = () => {
      treeData.value = parseMarkdownToTreeData(markdownText.value);
    };

    const setCurrentLayout = (layout) => {
      currentLayout.value = layout;
    };

    const capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    };
    const handleSave = () => {
      if (mindMapRef.value) {
        mindMapRef.value.saveEntireMindMap();
      }
    };
    const handleDelete = () => {
      // this.$em
      emit("delete");
    };
    onMounted(async () => {
      // canvasHeight.value = mapContainer.value?.clientHeight;
      // canvasWidth.value = mapContainer.value?.clientWidth;
      observer = new ResizeObserver((entries) => {
        for (let entry of entries) {
          const { width, height } = entry.contentRect;
          console.log("Div尺寸变化:", width, height);

          canvasHeight.value = height;
          canvasWidth.value = width;
          // 在这里你可以执行任何你需要的操作
        }
      });
      observer.observe(mapContainer.value);

      handleConvert();
      //   await nextTick();
      //   console.log(canvasHeight.value, canvasWidth.value);
    });
    onUnmounted(() => {
      if (observer) {
        observer.disconnect();
      }
    });

    return {
      mindMapRef,
      handleSave,
      handleDelete,
      currentTheme,
      canvasWidth,
      canvasHeight,
      mapContainer,
      currentLayout,
      currentColorScheme,
      markdownText,
      treeData,
      layouts,
      colorSchemes,
      handleConvert,
      setCurrentLayout,
      capitalizeFirstLetter,
    };
  },
};
</script>

<style scoped>
.container_page {
  display: flex;
  flex-direction: column;
  /* height: 100%; */
  /* width: 100%; */
}

.side {
  flex: 1;
  padding: 20px;
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.button {
  margin: 5px;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
}

.select {
  margin: 5px;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
}

.textarea {
  width: 100%;
  height: 80%;
  margin-top: 20px;
}
</style>
