<template>
  <div class="relative pt-24 pb-32 bg-white dark:bg-gray-900 isolate sm:pt-32">
    <div
      class="absolute inset-x-0 overflow-hidden -translate-y-1/2 top-1/2 -z-10 transform-gpu opacity-30 blur-3xl"
      aria-hidden="true"
    >
      <div
        class="ml-[max(50%,38rem)] aspect-[1313/771] w-[82.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc]"
        style="
          clip-path: polygon(
            74.1% 44.1%,
            100% 61.6%,
            97.5% 26.9%,
            85.5% 0.1%,
            80.7% 2%,
            72.5% 32.5%,
            60.2% 62.4%,
            52.4% 68.1%,
            47.5% 58.3%,
            45.2% 34.5%,
            27.5% 76.7%,
            0.1% 64.9%,
            17.9% 100%,
            27.6% 76.8%,
            76.1% 97.7%,
            74.1% 44.1%
          );
        "
      />
    </div>
    <div
      class="absolute inset-x-0 top-0 flex pt-32 overflow-hidden opacity-25 -z-10 transform-gpu blur-3xl sm:pt-40 xl:justify-end"
      aria-hidden="true"
    >
      <div
        class="ml-[-22rem] aspect-[1313/771] w-[82.0625rem] flex-none origin-top-right rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] xl:ml-0 xl:mr-[calc(50%-12rem)]"
        style="
          clip-path: polygon(
            74.1% 44.1%,
            100% 61.6%,
            97.5% 26.9%,
            85.5% 0.1%,
            80.7% 2%,
            72.5% 32.5%,
            60.2% 62.4%,
            52.4% 68.1%,
            47.5% 58.3%,
            45.2% 34.5%,
            27.5% 76.7%,
            0.1% 64.9%,
            17.9% 100%,
            27.6% 76.8%,
            76.1% 97.7%,
            74.1% 44.1%
          );
        "
      />
    </div>
    <div class="px-6 mx-auto max-w-7xl lg:px-8">
      <div class="max-w-xl mx-auto text-center">
        <p
          class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl dark:text-white"
        >
          {{ $t("comments") }}
        </p>
      </div>
      <div
        class="grid max-w-2xl grid-cols-1 grid-rows-1 gap-8 mx-auto mt-16 text-sm leading-6 text-gray-900 sm:mt-20 sm:grid-cols-2 xl:mx-0 xl:max-w-none xl:grid-flow-col xl:grid-cols-4"
      >
        <figure
          class="bg-white shadow-lg dark:bg-gray-700 dark:text-white rounded-2xl ring-1 ring-gray-900/5 sm:col-span-2 xl:col-start-2 xl:row-end-1"
        >
          <blockquote
            class="p-6 text-lg font-semibold leading-7 tracking-tight text-gray-900 dark:text-white sm:p-12 sm:text-xl sm:leading-8"
          >
            <p>{{ `“${featuredTestimonial.body}”` }}</p>
          </blockquote>
          <figcaption
            class="flex flex-wrap items-center px-6 py-4 border-t gap-x-4 gap-y-4 border-gray-900/10 sm:flex-nowrap"
          >
            <img
              class="flex-none w-10 h-10 rounded-full bg-gray-50"
              :src="featuredTestimonial.author.imageUrl"
              alt=""
            />
            <div class="flex-auto">
              <div class="font-semibold">
                {{ featuredTestimonial.author.name }}
              </div>
              <div class="text-gray-600 dark:text-white">
                {{ `@${featuredTestimonial.author.handle}` }}
              </div>
            </div>
            <img
              class="flex-none w-auto h-10"
              :src="featuredTestimonial.author.logoUrl"
              alt=""
            />
          </figcaption>
        </figure>
        <div
          v-for="(columnGroup, columnGroupIdx) in testimonials"
          :key="columnGroupIdx"
          class="space-y-8 xl:contents xl:space-y-0"
        >
          <div
            v-for="(column, columnIdx) in columnGroup"
            :key="columnIdx"
            :class="[
              (columnGroupIdx === 0 && columnIdx === 0) ||
              (columnGroupIdx === testimonials.length - 1 &&
                columnIdx === columnGroup.length - 1)
                ? 'xl:row-span-2'
                : 'xl:row-start-1',
              'space-y-8',
            ]"
          >
            <figure
              v-for="testimonial in column"
              :key="testimonial.author.handle"
              class="p-6 bg-white shadow-lg dark:bg-gray-700 dark:text-white rounded-2xl ring-1 ring-gray-900/5"
            >
              <blockquote class="text-gray-900 dark:text-white">
                <p>{{ `“${testimonial.body}”` }}</p>
              </blockquote>
              <figcaption class="flex items-center mt-6 gap-x-4">
                <img
                  class="w-10 h-10 rounded-full bg-gray-50"
                  :src="testimonial.author.imageUrl"
                  alt=""
                />
                <div>
                  <div class="font-semibold">{{ testimonial.author.name }}</div>
                  <div class="text-gray-600 dark:text-gray-300">
                    {{ `@${testimonial.author.handle}` }}
                  </div>
                </div>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();
import { ref, watch } from "vue";

let featuredTestimonial = ref("");
let testimonials = ref("");
function changeI18n() {
  if (locale.value == "en") {
    featuredTestimonial.value = {
      body: "I am a freelance writer who often needs to read and organize a large amount of research material. This tool helps me convert cumbersome PDF documents into intuitive mind maps, allowing me to capture key information and ideas more quickly. It has greatly improved my creative efficiency!",
      author: {
        name: "Emma Rodriguez",
        handle: "emmarodriguez",
        imageUrl:
          "https://images.unsplash.com/photo-1586297135537-94bc9ba060aa?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      },
    };
    testimonials.value = [
      [
        [
          {
            body: "This PDF to mind map tool is amazing! It allows me to quickly understand the complex structure of documents, greatly improving my learning efficiency. I highly recommend it to all students and researchers.",
            author: {
              name: "Emily Chen",
              handle: "emilychen",
              imageUrl:
                "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
            },
          },
          {
            body: "While preparing my doctoral thesis, this PDF to mind map tool became my lifesaver. It helped me organize a large amount of academic literature, allowing me to build my research framework more clearly. This is an essential tool for any graduate student dealing with complex information.",
            author: {
              name: "Daniel Kim",
              handle: "danielkim",
              imageUrl:
                "https://images.unsplash.com/photo-1456327102063-fb5054efe647?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
            },
          },
        ],
        [
          {
            body: "As a teacher, I have always been looking for tools that can help students better understand complex concepts. This PDF to mind map service is exactly what I needed. It not only helps me create teaching materials but also encourages students to learn independently. Highly recommended for all educators!",
            author: {
              name: "Lisa Wong",
              handle: "lisawong",
              imageUrl:
                "https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
            },
          },
        ],
      ],
      [
        [
          {
            body: "This tool is simply my secret weapon for exam preparation! It helps me convert bulky textbooks into easy-to-understand and remember mind maps. My study efficiency has increased by at least 50%. Thanks to this amazing service!",
            author: {
              name: "David Lee",
              handle: "davidlee",
              imageUrl:
                "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
            },
          },
        ],
        [
          {
            body: "I was initially skeptical about online tools, but this service completely changed my mind. The interface is intuitive, the conversion speed is fast, and the quality of the generated mind maps is very high. Most importantly, it really saved me a lot of time.",
            author: {
              name: "Sarah Johnson",
              handle: "sarahj",
              imageUrl:
                "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
            },
          },
          {
            body: "As the founder of a startup, I deal with various business plans and reports every day. This PDF to mind map tool allows me to quickly extract key information and share ideas with my team. It not only saves us time but also improves our decision-making efficiency. Highly recommended for all entrepreneurs!",
            author: {
              name: "Olivia Chang",
              handle: "oliviachang",
              imageUrl:
                "https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
            },
          },
        ],
      ],
    ];
  } else if (locale.value == "zh") {
    featuredTestimonial.value = {
      body: "我是一名自由撰稿人，经常需要阅读和整理大量的研究资料。这个工具帮我将繁琐的PDF文档转换成直观的脑图，使我能够更快地捕捉关键信息和灵感。它极大地提升了我的创作效率！",
      author: {
        name: "Emma Rodriguez",
        handle: "emmarodriguez",
        imageUrl:
          "https://images.unsplash.com/photo-1586297135537-94bc9ba060aa?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      },
    };
    testimonials.value = [
      [
        [
          {
            body: "这个PDF转脑图工具真是太棒了！它让我能够快速理解复杂的文档结构，大大提高了我的学习效率。强烈推荐给所有学生和研究人员。",
            author: {
              name: "Emily Chen",
              handle: "emilychen",
              imageUrl:
                "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
            },
          },
          {
            body: "在准备我的博士论文时，这个PDF转脑图工具成为了我的救星。它帮助我梳理了大量的学术文献，让我能够更清晰地构建研究框架。对于任何需要处理复杂信息的研究生来说，这都是一个必备工具。",
            author: {
              name: "Daniel Kim",
              handle: "danielkim",
              imageUrl:
                "https://images.unsplash.com/photo-1456327102063-fb5054efe647?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
            },
          },
        ],
        [
          {
            body: "作为一名教师，我一直在寻找能够帮助学生更好理解复杂概念的工具。这个PDF转脑图服务正是我所需要的。它不仅帮助我制作教学材料，也鼓励学生自主学习。强烈推荐给所有教育工作者！",
            author: {
              name: "Lisa Wong",
              handle: "lisawong",
              imageUrl:
                "https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
            },
          },
        ],
      ],
      [
        [
          {
            body: "这个工具简直就是我备考的秘密武器！它帮我将厚重的教科书转化为易于理解和记忆的脑图。我的学习效率提高了至少50%。感谢这个神奇的服务！",
            author: {
              name: "David Lee",
              handle: "davidlee",
              imageUrl:
                "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
            },
          },
        ],
        [
          {
            body: "我原本对在线工具持怀疑态度，但这个服务彻底改变了我的看法。界面直观，转换速度快，而且生成的脑图质量非常高。最重要的是，它真的帮我节省了大量时间。",
            author: {
              name: "Sarah Johnson",
              handle: "sarahj",
              imageUrl:
                "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
            },
          },
          {
            body: "作为一家初创公司的创始人，我每天都在处理各种商业计划和报告。这个PDF转脑图工具让我能够快速提取关键信息，与团队分享想法。它不仅节省了我们的时间，还提高了我们的决策效率。强烈推荐给所有企业家！",
            author: {
              name: "Olivia Chang",
              handle: "oliviachang",
              imageUrl:
                "https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
            },
          },
        ],
      ],
    ];
  }
}

changeI18n();

watch(locale, () => {
  changeI18n();
});
</script>
