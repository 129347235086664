<template>
  <div class="flex flex-col items-center justify-center">
    <div
      v-if="loading"
      class="relative inline-flex items-center h-10 text-sm font-semibold text-white bg-indigo-200 rounded-md shadow-sm w-36 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
    >
      <div
        class="h-full duration-300 bg-indigo-500 rounded-md"
        :style="'width: ' + loading + '%'"
      ></div>
      <div class="absolute inset-0 flex items-center justify-center">
        {{ loading }}%
      </div>
    </div>
    <label
      v-else
      class="inline-flex items-center h-10 px-3 py-2 text-sm font-semibold text-white bg-indigo-500 rounded-md shadow-sm w-36 hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
    >
      <PlusIcon class="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
      <!-- New Project -->
      {{ $t("dashboard.addTask") }}
      <input
        id="pdf-upload"
        type="file"
        @change="handleFileChange"
        accept="application/pdf"
        hidden
      />
    </label>
    <div class="mt-2 text-gray-300" v-if="status">{{ status }}</div>
  </div>
</template>

<script>
import { PlusIcon } from "@heroicons/vue/20/solid";
import { storage, db } from "../firebase/firebaseInit";
import { doc, setDoc, addDoc, collection, getDoc } from "firebase/firestore";
import { getCurrentUser } from "vuefire";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

import axios from "axios";
export default {
  components: {
    PlusIcon,
  },
  data() {
    return {
      loading: 0,
      status: "",
    };
  },
  async mounted() {},
  methods: {
    handleFileChange(event) {
      let that = this;
      const file = event.target.files[0];
      // console.log(file);
      if (file.size > 1024 * 1024 * 20) {
        alert("file size exceeds 20MB");
        return;
      }

      this.loading = 10;
      this.status = "Uploading pdf...";
      const storage = getStorage();

      // Create the file metadata
      /** @type {any} */
      const metadata = {
        contentType: "application/pdf",
      };

      // console.log(file.name);
      // Upload file and metadata to the object 'images/mountains.jpg'
      const storageRef = ref(storage, "pdf/" + file.name);
      const uploadTask = uploadBytesResumable(storageRef, file, metadata);

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          //   console.log({ snapshot });
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          //   console.log("Upload is " + progress + "% done");
          that.loading = (progress * 0.5).toFixed(2);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
          }
        },
        (error) => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;
            case "storage/canceled":
              // User canceled the upload
              break;
            // ...
            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log("File available at", downloadURL);
            that.analyze(downloadURL, file);
            // that.loading = 100;
          });
        }
      );
    },
    async analyze(url, file) {
      console.log(url, file);
      const user = await getCurrentUser();
      // const pdfUrl ="https://firebasestorage.googleapis.com/v0/b/mindmap-c9275.appspot.com/o/pdf%2FAS6040-Datasheet.pdf?alt=media&token=0d14bdc4-b088-4da7-a2e2-06d42a9eba8b";

      let res = await addDoc(collection(db, "history"), {
        pdf_url: url,
        update_time: Date.now(),
        pdf_name: file.name,
        user_id: user.uid,
        file_size: file.size,
      });
      // console.log({ res });

      // const apiUrl = "http://118.26.36.233:3389/generate_mindmap";
      // const apiUrl = "https://molly-open-robin.ngrok-free.app/generate_mindmap";
      const apiUrl =
        "https://pangpangchao-pdf2mindmap.hf.space/generate_mindmap";
      let analyze_res = await axios.get(apiUrl, {
        params: {
          url: url,
          id: res.id,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer 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",
        },
        // headers: {
        //   "X-API-Key":
        //     "sk-or-v1-4hjuikdfsljfkljasldj81b07fjdklsajlkfjljsb81dsahjkfhkdjsahkfed97bba48",
        // },
      });
      this.getStatus(res.id, file);
      // this.loading = 100;
      // const docRef = doc(db, "history", res.id);
      // const docSnap = await getDoc(docRef);

      // if (docSnap.exists()) {
      //   this.$emit("update", {
      //     markdown: docSnap.data().mindmap,
      //     mindmap: docSnap.data().mindmap,
      //     file,
      //   });
      //   this.loading = 0;
      // } else {
      //   console.log("No such document!");
      // }
    },
    // async getStatus(id,file) {

    //   // 每5s查询一下firebase
    //   const docRef = doc(db, "history", id);
    //   let docSnap = await getDoc(docRef);

    //   if (!docSnap.exists()) {
    //     this.loading = 0;
    //     this.status = "Project not exists!";
    //     return;
    //   }
    //   console.log(docSnap.data().status)
    //   if (docSnap.data().status == "Updated firebase") {
    //     this.loading = 100;
    //     this.$emit("update", {
    //       markdown: docSnap.data().mindmap,
    //       mindmap: docSnap.data().mindmap,
    //       file,
    //     });
    //     this.loading = 0;
    //   } else {
    //     this.loading = (this.loading + (100-this.loading)*0.1)
    //     this.status = docSnap.data().status
    //     setTimeout((id,file)=>this.getStatus(id,file), 5000);
    //   }
    // },
    async getStatus(id, file) {
      try {
        const docRef = doc(db, "history", id);
        const docSnap = await getDoc(docRef);

        if (!docSnap.exists()) {
          this.loading = 0;
          this.status = "Project not exists!";
          return;
        }

        // console.log(docSnap.data().status);
        // console.log(`docSnap.data().status == "Updated Firebase"`,docSnap.data().status == "Updated Firebase");

        if (docSnap.data().status == "Updated Firebase") {
          this.loading = 100;
          this.$emit("update", {
            markdown: docSnap.data().mindmap,
            mindmap: docSnap.data().mindmap,
            file,
          });
          this.loading = 0;
        } else {
          // this.loading = Math.min(this.loading + 10, 99); // 确保 loading 不超过 99
          this.loading = Math.floor(this.loading + (100-this.loading)*0.1)
          this.status = docSnap.data().status;

          // 使用 bind 来正确传递 this 上下文和参数
          this.timeoutId = setTimeout(
            this.getStatus.bind(this, id, file),
            5000
          );
        }
      } catch (error) {
        console.error("Error fetching status:", error);
        this.status = "Error fetching status";
        this.loading = 0;
      }
    },
    // 在组件销毁时清除定时器
    beforeDestroy() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
    },
  },
};
</script>
