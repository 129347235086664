import { BoundingBox, Layout } from 'non-layered-tidy-tree-layout';
import {
  MAX_WIDTH,
  LINE_HEIGHT,
  MARGIN,
  MIN_WIDTH,
  MIN_HEIGHT,
  LAYOUT_GAP,
  LAYOUT_PAD,
  LayoutType,
  ColorPalette,
} from './config';

class LayoutManager {
  constructor(p, layoutType, colorScheme) {
    this.p = p;
    this.layoutType = layoutType;
    this.colorScheme = colorScheme;
    this.layout = new Layout(new BoundingBox(LAYOUT_GAP, LAYOUT_PAD));
  }

  createColorObject(colors, index) {
    return {
      background: colors.background[index],
      text: colors.text[index % colors.text.length],
      border: colors.border[index % colors.border.length],
      stroke: colors.stroke[index % colors.stroke.length],
    };
  }

  assignColors(node, parentColor = null) {
    const colors = ColorPalette[this.colorScheme];
    if (!parentColor) {
      node.color = this.createColorObject(colors, 0);

      if (node.children) {
        node.children.forEach((child, index) => {
          const colorIndex = (index + 1) % colors.background.length;
          child.color = this.createColorObject(colors, colorIndex);
          this.assignColors(child, child.color);
        });
      }
    } else {
      node.color = parentColor;
      if (node.children) {
        node.children.forEach((child) => {
          this.assignColors(child, parentColor);
        });
      }
    }

    return node;
  }

  calculateTextDimensions(text) {
    if (text === undefined || typeof text !== 'string') {
      console.warn(`Invalid text input: ${text}. Using empty string instead.`);
      text = '';
    }

    const words = text.split(' ');
    let lines = 1;
    let currentLineWidth = 0;
    let maxLineWidth = 0;

    for (const word of words) {
      const wordWidth = this.p.textWidth(word + ' ');

      if (currentLineWidth + wordWidth > MAX_WIDTH) {
        lines++;
        maxLineWidth = Math.max(maxLineWidth, currentLineWidth);
        currentLineWidth = wordWidth;
      } else {
        currentLineWidth += wordWidth;
      }
    }

    maxLineWidth = Math.max(maxLineWidth, currentLineWidth);

    const width = Math.max(Math.min(maxLineWidth, MAX_WIDTH) + MARGIN * 2, MIN_WIDTH);
    const height = Math.max(lines * LINE_HEIGHT + MARGIN * 2, MIN_HEIGHT);

    return { width, height };
  }

  updateTreeDataDimensions(node, layoutType = this.layoutType) {
    const nodeText = node && typeof node.content !== 'undefined' ? String(node.content) : '';
    const { width, height } = this.calculateTextDimensions(nodeText);

    if (layoutType === LayoutType.DefaultLayout) {
      node.width = Math.max(width, MIN_WIDTH);
      node.height = Math.max(height, MIN_HEIGHT);
    } else {
      node.width = Math.max(height, MIN_HEIGHT);
      node.height = Math.max(width, MIN_WIDTH);
    }

    if (node.children) {
      node.children = node.children.map((child) =>
        this.updateTreeDataDimensions(child, layoutType)
      );
    }

    return node;
  }

  centerMindMap(result, boundingBox, canvasWidth, canvasHeight) {
    const xOffset = canvasWidth / 2 - (boundingBox.left + boundingBox.right) / 2;
    const yOffset = canvasHeight / 2 - (boundingBox.top + boundingBox.bottom) / 2;
    function updateNodePosition(node) {
      node.x += xOffset;
      node.y += yOffset;
      if (node.children) {
        node.children.forEach(updateNodePosition);
      }
    }
    updateNodePosition(result);

    const centerBoundingBox = {
      left: boundingBox.left + xOffset,
      right: boundingBox.right + xOffset,
      top: boundingBox.top + yOffset,
      bottom: boundingBox.bottom + yOffset,
    };

    return { centerResult: result, centerBoundingBox };
  }

  addPositionToTree(tree, position, depth = 0) {
    if (depth === 0) {
      tree.position = null;
    } else {
      tree.position = position;
    }
    if (tree.children) {
      tree.children = tree.children.map((child) =>
        this.addPositionToTree({ ...child }, position, depth + 1)
      );
    }
    return tree;
  }

  splitTreeLeftRight(node) {
    if (!node || !node.children || node.children.length === 0) {
      return {
        leftTree: this.addPositionToTree({ ...node, children: [] }, 'left'),
        rightTree: this.addPositionToTree({ ...node, children: [] }, 'right'),
      };
    }

    const midIndex = Math.ceil(node.children.length / 2);
    return {
      leftTree: this.addPositionToTree(
        { ...node, children: node.children.slice(0, midIndex) },
        'left'
      ),
      rightTree: this.addPositionToTree(
        { ...node, children: node.children.slice(midIndex) },
        'right'
      ),
    };
  }

  mergeLeftRightTrees(leftRoot, rightRoot) {
    return { ...leftRoot, children: [...(leftRoot.children || []), ...(rightRoot.children || [])] };
  }

  updateRightTreePosition(rightTree, xOffset, yOffset) {
    function updatePosition(node) {
      node.x += 2 * xOffset;
      node.y += 2 * yOffset;
      if (node.children) {
        node.children.forEach(updatePosition);
      }
    }
    updatePosition(rightTree);
    return rightTree;
  }

  updateLeftTreePosition(leftTree, xOffset, yOffset) {
    function updatePosition(node) {
      node.x -= xOffset;
      node.y -= yOffset;
      if (node.children) {
        node.children.forEach(updatePosition);
      }
    }
    updatePosition(leftTree);
    return leftTree;
  }

  transformCoordinates(node) {
    const { x, y, width, height, position } = node;

    const assert = (condition, message) => {
      if (!condition) {
        throw new Error(message);
      }
    };

    switch (this.layoutType) {
      case 'DefaultLayout':
        assert(
          position === null || position === 'bottom',
          `Invalid position for DefaultLayout: ${position}`
        );
        return [x, y, width, height];
      case 'RightLayout':
        assert(
          position === null || position === 'right',
          `Invalid position for RightLayout: ${position}`
        );
        return [y, x, height, width];
      case 'LeftLayout':
        assert(
          position === null || position === 'left',
          `Invalid position for LeftLayout: ${position}`
        );
        return [this.p.width - y - height, x, height, width];
      case 'StandardLayout':
        return [y, x, height, width];
      default:
        throw new Error(`Unknown layout type: ${this.layoutType}`);
    }
  }

  applyCoordinateTransformation(node, boundingBox) {
    [node.x, node.y, node.width, node.height] = this.transformCoordinates(node);
    if (node.children) {
      node.children.forEach((child) => this.applyCoordinateTransformation(child, boundingBox));
    }

    return node;
  }

  calculateNodeBoundaries(node) {
    let minX = Infinity;
    let maxX = -Infinity;
    let minY = Infinity;
    let maxY = -Infinity;

    function updateBoundaries(x, y, width, height) {
      minX = Math.min(minX, x);
      maxX = Math.max(maxX, x + width);
      minY = Math.min(minY, y);
      maxY = Math.max(maxY, y + height);
    }

    function traverse(node) {
      if (node.x !== undefined && node.y !== undefined) {
        updateBoundaries(node.x, node.y, node.width || 0, node.height || 0);
      }

      if (node.children && node.children.length > 0) {
        node.children.forEach(traverse);
      }
    }

    traverse(node);

    return { left: minX, right: maxX, top: minY, bottom: maxY };
  }

  processLayout(treeData, canvasWidth, canvasHeight) {
    const coloredTreeData = this.assignColors(JSON.parse(JSON.stringify(treeData)));

    if (this.layoutType === LayoutType.StandardLayout) {
      const { leftTree, rightTree } = this.splitTreeLeftRight(coloredTreeData);

      this.layoutType = LayoutType.LeftLayout;
      const updatedLeftTree = this.updateTreeDataDimensions(leftTree);
      const leftTreeWithPosition = this.addPositionToTree(updatedLeftTree, 'left');
      const leftLayoutResult = this.layout.layout(leftTreeWithPosition);
      let leftResult = leftLayoutResult.result;
      leftResult = this.applyCoordinateTransformation(leftResult);

      this.layoutType = LayoutType.RightLayout;
      const updatedRightTree = this.updateTreeDataDimensions(rightTree);
      const rightTreeWithPosition = this.addPositionToTree(updatedRightTree, 'right');
      const rightLayoutResult = this.layout.layout(rightTreeWithPosition);
      let rightResult = rightLayoutResult.result;
      rightResult = this.applyCoordinateTransformation(rightResult);

      const updatedRightResult = this.updateRightTreePosition(
        rightResult,
        (leftResult.x - rightResult.x) / 2,
        (leftResult.y - rightResult.y) / 2
      );
      const updatedLeftResult = this.updateLeftTreePosition(
        leftResult,
        (leftResult.x - rightResult.x) / 2,
        (leftResult.y - rightResult.y) / 2
      );

      const mergedRoot = this.mergeLeftRightTrees(updatedLeftResult, updatedRightResult);
      const boundingBox = this.calculateNodeBoundaries(mergedRoot);
      this.layoutType = LayoutType.StandardLayout;
      const { centerResult, centerBoundingBox } = this.centerMindMap(
        mergedRoot,
        boundingBox,
        canvasWidth,
        canvasHeight
      );

      return { centerResult, centerBoundingBox };
    } else {
      const updatedTreeData = this.updateTreeDataDimensions(coloredTreeData);

      const getPositionForLayout = (layoutType) => {
        switch (layoutType) {
          case LayoutType.DefaultLayout:
            return 'bottom';
          case LayoutType.LeftLayout:
            return 'left';
          case LayoutType.RightLayout:
            return 'right';
          default:
            throw new Error(`未知的布局类型: ${layoutType}`);
        }
      };
      const treeWithPosition = this.addPositionToTree(
        updatedTreeData,
        getPositionForLayout(this.layoutType)
      );

      const layoutResult = this.layout.layout(treeWithPosition);
      const result = layoutResult.result;

      let transCoordinateResult;
      transCoordinateResult = this.applyCoordinateTransformation(result);
      const transBoundingBox = this.calculateNodeBoundaries(transCoordinateResult);
      const { centerResult, centerBoundingBox } = this.centerMindMap(
        transCoordinateResult,
        transBoundingBox,
        canvasWidth,
        canvasHeight
      );
      return { centerResult, centerBoundingBox };
    }
  }
}

export default LayoutManager;