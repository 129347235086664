<template>
  <div class="w-full h-screen overflow-y-scroll lg:overflow-hidden">
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog
        as="div"
        class="relative z-50 lg:hidden"
        @close="sidebarOpen = false"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-gray-900/80" />
        </TransitionChild>

        <div class="fixed inset-0 flex">
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full"
          >
            <DialogPanel class="relative flex flex-1 w-full max-w-xs mr-16">
              <TransitionChild
                as="template"
                enter="ease-in-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div
                  class="absolute top-0 flex justify-center w-16 pt-5 left-full"
                >
                  <button
                    type="button"
                    class="-m-2.5 p-2.5"
                    @click="sidebarOpen = false"
                  >
                    <span class="sr-only">Close sidebar</span>
                    <XMarkIcon class="w-6 h-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <div
                class="flex flex-col px-6 pb-2 overflow-y-auto bg-white dark:bg-gray-800 grow gap-y-5"
              >
                <a class="flex items-center h-16 shrink-0" href="/">
                  <div class="flex items-center space-x-2">
                    <svg
                      class="w-8 h-8"
                      style="color: #6366f1"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M13 10V3L4 14h7v7l9-11h-7z"
                      ></path>
                    </svg>
                    <span class="text-xl font-bold" style="color: #6366f1"
                      >PDF2MindMap</span
                    >
                  </div>
                </a>
                <nav class="flex flex-col flex-1">
                  <ul role="list" class="flex flex-col flex-1 gap-y-7">
                    <li>
                      <ul role="list" class="-mx-2 space-y-1">
                        <li
                          v-for="(item, index) in navigation"
                          :key="item.name"
                          @click="navigateTo(index)"
                        >
                          <div
                            :href="item.href"
                            :class="[
                              current_navigation == index
                                ? 'bg-gray-50 text-indigo-600 dark:bg-gray-800 dark:text-white'
                                : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-white',
                              'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                            ]"
                          >
                            <component
                              :is="item.icon"
                              :class="[
                                current_navigation == index
                                  ? 'text-indigo-600 dark:text-white'
                                  : 'text-gray-400 group-hover:text-indigo-600 dark:group-hover:text-white',
                                'h-6 w-6 shrink-0',
                              ]"
                              aria-hidden="true"
                            />
                            {{ item.name }}
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li class="mt-auto -mx-6">
                      <UserConfig></UserConfig>
                    </li>
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>

    <div
      class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col"
    >
      <div
        class="flex flex-col px-6 overflow-y-auto bg-white border-r border-gray-200 dark:border-gray-700 dark:bg-gray-900 grow gap-y-5"
      >
        <a class="flex items-center h-16 shrink-0" href="/">
          <div class="flex items-center space-x-2">
            <svg
              class="w-8 h-8"
              style="color: #6366f1"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M13 10V3L4 14h7v7l9-11h-7z"
              ></path>
            </svg>
            <span class="text-xl font-bold" style="color: #6366f1"
              >PDF2MindMap</span
            >
          </div>
        </a>
        <nav class="flex flex-col flex-1">
          <ul role="list" class="flex flex-col flex-1 gap-y-7">
            <li>
              <ul role="list" class="-mx-2 space-y-1">
                <li
                  v-for="(item, index) in navigation"
                  :key="item.name"
                  @click="navigateTo(index)"
                >
                  <div
                    :href="item.href"
                    :class="[
                      current_navigation == index
                        ? 'bg-gray-50 text-indigo-600 dark:bg-gray-800 dark:text-white'
                        : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-white',
                      'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                    ]"
                  >
                    <component
                      :is="item.icon"
                      :class="[
                        current_navigation == index
                          ? 'text-indigo-600 dark:text-white'
                          : 'text-gray-400 group-hover:text-indigo-600 dark:group-hover:text-white',
                        'h-6 w-6 shrink-0',
                      ]"
                      aria-hidden="true"
                    />
                    {{ item.name }}
                  </div>
                </li>
              </ul>
            </li>

            <li class="mt-auto -mx-6">
              <UserConfig></UserConfig>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <div
      class="sticky top-0 z-40 flex items-center px-4 py-4 bg-white shadow-sm dark:bg-gray-800 dark:text-white gap-x-6 sm:px-6 lg:hidden"
    >
      <button
        type="button"
        class="-m-2.5 p-2.5 text-gray-700 dark:text-white lg:hidden"
        @click="sidebarOpen = true"
      >
        <span class="sr-only">Open sidebar</span>
        <Bars3Icon class="w-6 h-6" aria-hidden="true" />
      </button>
      <div
        class="flex-1 text-sm font-semibold leading-6 text-gray-900 dark:text-white"
      >
        {{ navigation[current_navigation].name }}
      </div>
      <a href="#">
        <span class="sr-only">Your profile</span>
      </a>
    </div>

    <div
      class="flex items-center justify-center w-full h-screen lg:pl-72 dark:bg-gray-900 dark:text-white"
    >
      <DashboardPage v-show="current_navigation == 0"></DashboardPage>
      <HistoryPage v-if="current_navigation == 1"></HistoryPage>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, computed } from "vue";
import { getCurrentUser } from "vuefire";

import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();

import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import {
  Bars3Icon,
  CalendarIcon,
  HomeIcon,
  XMarkIcon,
} from "@heroicons/vue/24/outline";
import UserConfig from "@/components/UserConfig";
import DashboardPage from "@/components/DashboardPage";
import HistoryPage from "@/components/HistoryPage";
import { useRouter } from "vue-router";

let router = useRouter();
onMounted(async () => {
  const user = await getCurrentUser();
  console.log(user);
  if (!user) {
    router.push("/login");
  }
});
const navigation = computed(() => [
  { name: t("dashboard.dashboard"), icon: HomeIcon },
  { name: t("dashboard.history"), icon: CalendarIcon },
])
// const navigation = ref([
//   { name: t("dashboard.dashboard"), icon: HomeIcon },
//   { name: t("dashboard.history"), icon: CalendarIcon },
// ]);

let current_navigation = ref(0);

const sidebarOpen = ref(false);
function navigateTo(index) {
  current_navigation.value = index;
}
</script>
