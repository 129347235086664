<template>
  <div class="w-full h-screen">
    <div class="w-full h-screen" v-if="!showResults">
      <div class="flex items-center justify-center w-full h-screen">
        <div class="text-center">
          <svg
            class="w-12 h-12 mx-auto text-gray-400 dark:text-white"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              vector-effect="non-scaling-stroke"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
            />
          </svg>
          <h3 class="mt-2 text-sm font-semibold text-gray-900 dark:text-white">
            <!-- No projects -->
            {{ $t("dashboard.empty") }}
          </h3>
          <p class="mt-1 mb-6 text-sm text-gray-500 dark:text-white">
            <!-- Get started by creating a new project. -->
            {{ $t("dashboard.emptyTips") }}
          </p>
          <UploadBtn @update="getResult"></UploadBtn>
        </div>
      </div>
    </div>
    <div class="flex flex-col h-screen lg:flex-row" v-else>
      <div
        class="flex flex-col h-full p-2 px-8 py-6 duration-300"
        :class="smallpdf ? 'flex-[1_1_0%]' : 'flex-[2_1_0%]'"
      >
        <div class="flex items-center py-4 mb-8">
          <UploadBtn @update="getResult"></UploadBtn>
          <div class="flex-1 ml-2 text-sm text-gray-500 dark:text-white">
            {{ $t("dashboard.successed") }}
          </div>
        </div>
        <div class="flex-1 w-full lg:h-full" style="min-height: 600px">
          <vue-pdf-app
            v-if="pdfUrl"
            :pdf="pdfUrl"
            :config="config"
          ></vue-pdf-app>
        </div>
      </div>
      <div class="items-center hidden h-full border-r lg:flex">
        <div
          class="bg-gray-100 border-t border-b border-l rounded-l-lg dark:bg-gray-700 dark:border-gray-500"
          @click="changePdfView"
        >
          <ChevronLeftIcon
            v-if="!smallpdf"
            class="w-6 h-6 my-10 text-gray-500"
            aria-hidden="true"
          />
          <ChevronRightIcon
            v-else
            class="w-6 h-6 my-10 text-gray-500"
            aria-hidden="true"
          />
        </div>
      </div>
      <div class="flex-[3_1_0%] p-2">
        <div
          class="flex flex-col w-full h-screen px-4 py-10 sm:px-6 lg:px-8 lg:py-6"
          v-if="showResults"
        >
          <div class="mb-8">
            <div class="sm:hidden">
              <label for="tabs" class="sr-only">Select a tab</label>
              <select
                id="tabs"
                name="tabs"
                @change="changeTabSmall($event.target.value)"
                class="block w-full py-2 pl-3 pr-10 text-base border-gray-300 rounded-md focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              >
                <option
                  v-for="tab in tabs"
                  :key="tab.name"
                  :selected="tab.current"
                >
                  {{ tab.name }}
                </option>
              </select>
            </div>
            <div class="hidden sm:block">
              <div class="border-b border-gray-200">
                <nav class="flex -mb-px space-x-8" aria-label="Tabs">
                  <a
                    v-for="tab in tabs"
                    :key="tab.name"
                    @click="changeTab(tab)"
                    :class="[
                      tab.current
                        ? 'border-indigo-500 text-indigo-600'
                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                      'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium',
                    ]"
                    :aria-current="tab.current ? 'page' : undefined"
                    >{{ tab.name }}</a
                  >
                </nav>
              </div>
            </div>
          </div>
          <div class="flex-1 w-full" v-if="tabs[0].current">
            <MindMap :content="mindmap_content" :showDelete="false" />
          </div>
          <div class="flex-1 w-full overflow-scroll" v-if="tabs[1].current">
            <MarkDown :content="mindmap_content" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import VuePdfApp from "vue3-pdf-app";
import "vue3-pdf-app/dist/icons/main.css";

import MarkDown from "../components/result/markdown";
import UploadBtn from "../components/UploadBtn";
import MindMap from "@/components/result/mindmapPage";

import { onMounted, ref } from "vue";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/24/outline";

const smallpdf = ref(false);
let tabs = ref([
  { name: "MindMap", href: "#", current: true },
  { name: "MarkDown", href: "#", current: false },
]);
let showResults = ref(false);
let pdfUrl = ref("");
let markdown_content = ref("");
let mindmap_content = ref(null);

const getResult = ({ markdown, mindmap, file }) => {
  console.log(markdown, mindmap, file);
  if (file && file.type === "application/pdf") {
    const reader = new FileReader();
    reader.onload = (e) => {
      pdfUrl.value = e.target.result;
      markdown_content.value = markdown;
      mindmap_content.value = mindmap;
      showResults.value = true;
    };
    reader.readAsDataURL(file);
  }
};
const config = {
  toolbar: false,
};

const changeTab = (tab) => {
  console.log(tab);
  tabs.value.forEach((t) => (t.current = t.name === tab.name));
};

const changeTabSmall = (e) => {
  tabs.value.forEach((t) => (t.current = t.name === e));
};

const changePdfView = () => {
  smallpdf.value = !smallpdf.value;
  let tmp = pdfUrl.value;
  pdfUrl.value = null;
  setTimeout(() => {
    pdfUrl.value = tmp;
  }, 100);
};
</script>
