<template>
  <header
    :class="[
      'p-4 shadow-lg transition-colors duration-300  text-white bg-gradient-to-r from-purple-600 dark:from-black to-indigo-600 dark:to-black',
    ]"
  >
    <div class="container mx-auto">
      <div class="flex items-center justify-between">
        <div class="flex items-center space-x-2">
          <svg
            class="w-8 h-8"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M13 10V3L4 14h7v7l9-11h-7z"
            ></path>
          </svg>
          <span class="text-2xl font-bold">PDF2MindMap</span>
        </div>
        <div class="items-center hidden space-x-6 md:flex">
          <nav>
            <ul class="flex space-x-6">
              <li v-for="item in menuItems" :key="item.id">
                <a
                  :href="item.link"
                  class="transition duration-300 hover:text-purple-600"
                  >{{ item.name }}</a
                >
              </li>
            </ul>
          </nav>
          <div class="flex items-center space-x-4">
            <!-- Cool Dark Mode Toggle -->
            <button
              @click="toggleDarkMode"
              class="relative flex items-center h-8 p-1 transition-colors duration-300 bg-gray-300 rounded-full w-14 focus:outline-none"
              :class="{ 'bg-purple-600': isDarkMode }"
            >
              <div
                class="w-6 h-6 transition-transform duration-300 transform bg-white rounded-full shadow-md"
                :class="{ 'translate-x-6': isDarkMode }"
              ></div>
              <svg
                class="absolute w-5 h-5 text-yellow-400 left-1.5"
                :class="{ 'opacity-0': isDarkMode }"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
                ></path>
              </svg>
              <svg
                class="absolute w-5 h-5 text-indigo-200 right-1.5"
                :class="{ 'opacity-100': isDarkMode, 'opacity-0': !isDarkMode }"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
                ></path>
              </svg>
            </button>
            <!-- Language Switcher -->
            <div class="relative">
              <button
                @click="toggleLanguageMenu"
                class="flex items-center space-x-1 focus:outline-none"
              >
                <svg
                  class="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3 5h12M9 3v2m1.048 9.5A18.022 18.022 0 016.412 9m6.088 9h7M11 21l5-10 5 10M12.751 5C11.783 10.77 8.07 15.61 3 18.129"
                  ></path>
                </svg>
                <span>{{ currentLanguage }}</span>
              </button>
              <div
                v-if="isLanguageMenuOpen"
                class="absolute right-0 z-20 w-32 py-2 mt-2 bg-white rounded-md shadow-xl"
              >
                <a
                  v-for="lang in languages"
                  :key="lang.code"
                  href="#"
                  @click.prevent="changeLanguage(lang.code)"
                  class="block px-4 py-2 text-sm text-gray-700 hover:bg-purple-600 hover:text-white"
                >
                  {{ lang.name }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="md:hidden">
          <button @click="toggleMobileMenu" class="focus:outline-none">
            <svg
              class="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                v-if="!isMobileMenuOpen"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16"
              ></path>
              <path
                v-else
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <!-- Mobile menu -->
    <div v-if="isMobileMenuOpen" class="mt-4 md:hidden">
      <nav>
        <ul class="flex flex-col space-y-2">
          <li v-for="item in menuItems" :key="item.id">
            <a
              :href="item.link"
              class="block px-4 py-2 transition duration-300 hover:bg-purple-600 hover:text-white"
              >{{ item.name }}</a
            >
          </li>
        </ul>
      </nav>
      <div class="flex items-center justify-between px-4 mt-4">
        <button
          @click="toggleDarkMode"
          class="relative flex items-center h-8 p-1 transition-colors duration-300 bg-gray-300 rounded-full w-14 focus:outline-none"
          :class="{ 'bg-purple-600': isDarkMode }"
        >
          <div
            class="w-6 h-6 transition-transform duration-300 transform bg-white rounded-full shadow-md"
            :class="{ 'translate-x-6': isDarkMode }"
          ></div>
          <svg
            class="absolute w-5 h-5 text-yellow-400 left-1"
            :class="{ 'opacity-0': isDarkMode }"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
            ></path>
          </svg>
          <svg
            class="absolute w-5 h-5 text-indigo-200 right-1"
            :class="{ 'opacity-100': isDarkMode, 'opacity-0': !isDarkMode }"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
            ></path>
          </svg>
        </button>
        <div class="relative">
          <button
            @click="toggleLanguageMenu"
            class="flex items-center space-x-1 focus:outline-none"
          >
            <svg
              class="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M3 5h12M9 3v2m1.048 9.5A18.022 18.022 0 016.412 9m6.088 9h7M11 21l5-10 5 10M12.751 5C11.783 10.77 8.07 15.61 3 18.129"
              ></path>
            </svg>
            <span>{{ currentLanguage }}</span>
          </button>
          <div
            v-if="isLanguageMenuOpen"
            class="absolute right-0 z-20 w-32 py-2 mt-2 bg-white rounded-md shadow-xl"
          >
            <a
              v-for="lang in languages"
              :key="lang.code"
              href="#"
              @click.prevent="changeLanguage(lang.code)"
              class="block px-4 py-2 text-sm text-gray-700 hover:bg-purple-600 hover:text-white"
            >
              {{ lang.name }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup>
import { ref } from "vue";

import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();
import { useDarkMode } from "../composables/useDarkMode";

const { isDarkMode, toggleDarkMode } = useDarkMode();

const menuItems = [
];

const isMobileMenuOpen = ref(false);
const isLanguageMenuOpen = ref(false);
const currentLanguage = ref("");

const languages = [
  { code: "zh", name: "中文" },
  { code: "en", name: "English" },
];

currentLanguage.value = languages.find(
  (lang) => lang.code === locale.value
).name;

const toggleMobileMenu = () => {
  isMobileMenuOpen.value = !isMobileMenuOpen.value;
};

const toggleLanguageMenu = () => {
  isLanguageMenuOpen.value = !isLanguageMenuOpen.value;
};

const changeLanguage = (langCode) => {
  const selectedLang = languages.find((lang) => lang.code === langCode);
  if (selectedLang) {
    currentLanguage.value = selectedLang.name;
    locale.value = langCode;
    localStorage.setItem("locale", langCode);
  }
  isLanguageMenuOpen.value = false;
};
</script>
