<template>
  <section class="py-20 bg-gradient-to-br from-purple-100 dark:from-gray-900 to-indigo-100 dark:to-gray-500">
    <div class="container px-4 mx-auto max-w-7xl">
      <div class="flex flex-col items-center lg:flex-row">
        <!-- Left side: Text content -->
        <div class="pr-4 mb-10 lg:w-1/2 lg:mb-0">
          <h1 class="mb-6 text-4xl font-bold text-gray-800 lg:text-4xl dark:text-white">
            {{ $t("hero.title") }}
          </h1>
          <p class="mb-8 text-xl text-gray-600 dark:text-white">
            {{ $t("hero.description") }}
          </p>
          <div class="flex space-x-4">
            <a
              href="/dashboard"
              class="px-6 py-3 text-white transition duration-300 bg-purple-600 rounded-lg hover:bg-purple-700"
            >
            {{ $t("hero.try") }}
            </a>
            <!-- <a
              href="/dashboard"
              class="px-6 py-3 text-purple-600 transition duration-300 border border-purple-600 rounded-lg hover:bg-purple-100"
            >
            {{ $t("hero.learnMore") }}
            </a> -->
          </div>
        </div>

        <!-- Right side: Interactive demo -->
        <div class="w-full lg:w-1/2">
          <div class="p-8 bg-white rounded-lg shadow-xl dark:bg-gray-600">
            <Test/>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from "vue";

import Test from "@/components/Test";
const fileInput = ref(null);
const fileName = ref("");
const showMindMap = ref(false);

const startUpload = () => {
  fileInput.value.click();
};

const triggerFileInput = () => {
  fileInput.value.click();
};

const handleFileSelect = (event) => {
  const file = event.target.files[0];
  if (file && file.type === "application/pdf") {
    fileName.value = file.name;
    // 这里可以添加文件上传和处理逻辑
    setTimeout(() => {
      showMindMap.value = true;
    }, 1500); // 模拟处理时间
  } else {
    alert("请选择一个PDF文件");
  }
};

const handleFileDrop = (event) => {
  const file = event.dataTransfer.files[0];
  if (file && file.type === "application/pdf") {
    fileName.value = file.name;
    // 这里可以添加文件上传和处理逻辑
    setTimeout(() => {
      showMindMap.value = true;
    }, 1500); // 模拟处理时间
  } else {
    alert("请拖放一个PDF文件");
  }
};
</script>
