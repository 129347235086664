export default {
  home: {
    title: "PDF2MindMap",
    description: "将你的pdf转换为思维导图",
    button: "开始使用",
  },
  dialog: {
    notice: "提示",
    cancel: "取消",
    confirm: "确认",
  },
  login: {
    title: "登录到您的账户",
    tips: "不是会员？",
    signUp: "注册",
    email: "电子邮件地址",
    password: "密码",
    remember: "记住我",
    forget: "忘记密码？",
    signIn: "登录",
    continue: "或者继续使用",
    empty: "email和password不能为空",
  },
  userConfig: {
    language: "语言",
    bind: "已绑定",
    changePassword: "修改密码",
    currentPassword: "当前密码",
    newPassword: "新密码",
    confirmPassword: "确认密码",
    updatePassword: "更新密码",
    bindGoogleAccount: "绑定谷歌账号",
    bindGoogle: "绑定谷歌",
    darkMode: "夜间模式",
    logout: "登出",
  },
  dashboard: {
    dashboard: "工作台",
    history: "历史记录",
    empty: "暂无项目",
    emptyTips: "点击下方按钮创建一个新的项目",
    addTask: "新增分析任务",
    successed: "此任务已保存，点击左侧历史记录查看。",
    taskList: "任务列表",
    noMore: "没有更多了",
    noHistory: "没有历史记录",
    toDashboard: "前往工作台",
    analyzing: "任务正在分析中...",
    analyzingTips: "您需要等待几秒到几分钟的时间，这取决于当前的服务器状态。",
    deleteConfirm: "确定删除此任务吗？删除后不可恢复。",
    fileSizeLimit: "文件大小超过20MB",
  },
  faq: {
    faqs: "常见问题解答",
    description: "如果您有更多的问题，可以发送邮件到：",
    description1: "我们会在看到后给您回复。",
  },
  feature: {
    features: "功能",
    description:
      "为用户提供了一个完整的PDF到脑图的转换和管理解决方案，使用户能够方便地将PDF文档可视化为易于理解和操作的脑图格式。",
  },
  hero: {
    title: "将您的PDF转换为清晰的思维导图",
    description:
      "只需上传您的PDF文件，我们的智能工具就会为您生成一张直观的思维导图，帮助您更好地理解和组织信息。",
    try: "立即尝试",
    learnMore: "了解更多",
  },
  testimonials: {
    line1:
      "在这个信息爆炸的时代，科研工作者面临着前所未有的挑战。每天，大量的新研究成果如潮水般涌来，如何在茫茫文献海洋中捕捉关键信息，提炼核心思想，成为摆在我们面前的一道难题。正是基于这样的认知，我们开发了这个科研辅助网站。",
    line2:
      "目前，我们的网站允许用户上传PDF文件，并自动生成一个直观的思维导图。通过这个工具，希望能帮助大家快速把握论文的核心思想，提高阅读效率，激发创新灵感。",
    line3: "如果我比别人看得更远，那是因为我站在巨人的肩膀上。",
  },
  comments:"大家都在用"
};
