import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// import 'firebase/auth';

var firebaseConfig = {
  apiKey: "AIzaSyADSXeFOYO0ZeqYGtuHRP5QWYepOKM4490",
  authDomain: "mindmap-c9275.firebaseapp.com",
  projectId: "mindmap-c9275",
  storageBucket: "mindmap-c9275.appspot.com",
  messagingSenderId: "127815495418",
  appId: "1:127815495418:web:d96a2fe202a62a2c59e343",
  measurementId: "G-2WJE234FGX",
};

export const firebaseApp = initializeApp(firebaseConfig);

// const auth = auth();
export const db = getFirestore(firebaseApp);
// export const Auth = auth()

// export default {
//   auth,
//   db: getFirestore(firebaseApp),
// };

export const storage = getStorage(firebaseApp);
