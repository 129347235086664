<template>
  <div class="py-24 bg-white dark:bg-gray-900 sm:py-32">
    <div class="px-6 mx-auto max-w-7xl lg:px-8">
      <div class="max-w-2xl mx-auto lg:mx-0">
        <h2
          class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl dark:text-white"
        >
          {{ $t("feature.features") }}
        </h2>
        <p class="mt-6 text-lg leading-8 text-gray-600 dark:text-gray-300">
          {{ $t("feature.description") }}
        </p>
      </div>
      <div class="max-w-2xl mx-auto mt-16 sm:mt-20 lg:mt-24 lg:max-w-none">
        <dl
          class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3"
        >
          <div
            v-for="feature in features"
            :key="feature.name"
            class="flex flex-col"
          >
            <dt
              class="text-base font-semibold leading-7 text-gray-900 dark:text-gray-300"
            >
              <div
                class="flex items-center justify-center w-10 h-10 mb-6 bg-indigo-600 rounded-lg"
              >
                <component
                  :is="feature.icon"
                  class="w-6 h-6 text-white"
                  aria-hidden="true"
                />
              </div>
              {{ feature.name }}
            </dt>
            <dd
              class="flex flex-col flex-auto mt-1 text-base leading-7 text-gray-600 dark:text-gray-300"
            >
              <p class="flex-auto">{{ feature.description }}</p>
              <p class="mt-6">
                <a
                  :href="feature.href"
                  class="text-sm font-semibold leading-6 text-indigo-600"
                  >{{ $t("hero.learnMore") }}
                  <span aria-hidden="true">→</span></a
                >
              </p>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script setup>
import { InboxIcon, TrashIcon, UsersIcon } from "@heroicons/vue/24/outline";

import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();
import { ref, watch } from "vue";

let features = ref([]);

function changeI18n() {
  if (locale.value == "en") {
    features.value = [
      {
        name: "Task Management",
        description:
          "PDF file upload, automatic conversion of PDF to mind map format, support for multiple mind map layout options, real-time conversion progress display, and conversion completion notifications.",
        href: "/dashboard",
        icon: InboxIcon,
      },
      {
        name: "History Query",
        description:
          "View all historical conversion tasks, search historical tasks by date and file name, preview generated mind map thumbnails, download or delete historical mind maps, and track task status (pending, in progress, completed, failed).",
        href: "/dashboard",
        icon: UsersIcon,
      },
      {
        name: "Instant Modification",
        description:
          "Online mind map editor, drag and drop node position adjustment, add, delete, rename nodes, change node color and style, add links and notes, real-time auto-save, and export modified mind maps (support for multiple formats).",
        href: "/dashboard",
        icon: TrashIcon,
      },
    ];
  } else {
    features.value = [
      {
        name: "任务管理",
        description:
          "PDF文件上传、自动转换PDF为脑图格式、支持多种脑图布局选择、转换进度实时显示、转换完成通知。",
        href: "/dashboard",
        icon: InboxIcon,
      },
      {
        name: "历史查询",
        description:
          "查看所有历史转换任务、按日期、文件名搜索历史任务、预览生成的脑图缩略图、下载或删除历史脑图、任务状态跟踪（待处理、处理中、已完成、失败）",
        href: "/dashboard",
        icon: UsersIcon,
      },
      {
        name: "即时修改",
        description:
          "在线脑图编辑器、拖拽调整节点位置、添加、删除、重命名节点、更改节点颜色和样式、添加链接和附注、实时自动保存、导出修改后的脑图（多种格式支持）",
        href: "/dashboard",
        icon: TrashIcon,
      },
    ];
  }
}

changeI18n();

watch(locale, () => {
  changeI18n();
});
</script>
