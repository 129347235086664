<template>
  <div ref="sketchRef" class="min-w-0"></div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onUnmounted,
  watch,
  defineProps,
  defineExpose,
} from "vue";
import p5 from "p5";

import DrawNode from "./DrawNode";
import DrawLine from "./DrawLine";
import LayoutManager from "./LayoutManager";
import { LayoutType, FRAME_RATE, DarkTheme, LightTheme } from "./config";

const props = defineProps({
  treeData: Object,
  layoutType: {
    type: String,
    default: LayoutType.StandardLayout,
  },
  colorScheme: {
    type: String,
    default: "default",
  },
  theme: {
    type: String,
    default: "light",
    validator: (value) => ["light", "dark"].includes(value),
  },
  canvasWidth: {
    type: Number,
    default: 0,
  },
  canvasHeight: {
    type: Number,
    default: 0,
  },
});

const sketchRef = ref(null);
const p5InstanceRef = ref(null);
const layoutManagerRef = ref(null);

const saveEntireMindMap = () => {
  if (p5InstanceRef.value && layoutManagerRef.value) {
    saveEntireMindMapHelper(
      p5InstanceRef.value,
      layoutManagerRef.value,
      props.treeData
    );
  }
};
const createP5Instance = () => {
  if (p5InstanceRef.value) {
    p5InstanceRef.value.remove();
  }

  const sketch = (p) => {
    let layoutManager, result;
    let drawNode, drawLine;
    let scale = 1;
    let targetOffsetX = 0;
    let targetOffsetY = 0;
    let currentOffsetX = 0;
    let currentOffsetY = 0;
    let isDragging = false;
    let lastMouseX, lastMouseY;

    p.setup = () => {
      const canvas = p.createCanvas(props.canvasWidth, props.canvasHeight);
      canvas.mousePressed(handleMousePressed);
      canvas.mouseMoved(handleMouseDragged);
      canvas.mouseReleased(handleMouseReleased);

      layoutManager = new LayoutManager(p, props.layoutType, props.colorScheme);
      layoutManagerRef.value = layoutManager;
      const layoutResult = layoutManager.processLayout(
        props.treeData,
        props.canvasWidth,
        props.canvasHeight
      );
      result = layoutResult.centerResult;
      drawNode = new DrawNode(p, props.layoutType);
      drawLine = new DrawLine(p, props.layoutType);
      p.noLoop();
      p.redraw();
      p.frameRate(FRAME_RATE);
    };

    p.draw = () => {
      p.background(props.theme === "light" ? LightTheme : DarkTheme);
      currentOffsetX = p.lerp(currentOffsetX, targetOffsetX, 0.5);
      currentOffsetY = p.lerp(currentOffsetY, targetOffsetY, 0.5);
      p.push();
      p.translate(currentOffsetX, currentOffsetY);
      p.scale(scale);
      drawTree(result);
      p.pop();
      p.cursor(isDragging ? "grabbing" : "default");
      p.noLoop();
    };

    function drawTree(node) {
      const nodeCoords = drawNode.draw(node);
      if (node.children) {
        node.children.forEach((child) => {
          const childCoords = drawNode.draw(child);
          drawLine.draw(nodeCoords, childCoords, node.color, child.color);
          drawTree(child);
        });
      }
    }

    p.mouseWheel = (event) => {
      // 检查鼠标是否在画布内
      if (
        p.mouseX >= 0 &&
        p.mouseX <= p.width &&
        p.mouseY >= 0 &&
        p.mouseY <= p.height
      ) {
        const zoomSensitivity = 0.0005;
        const oldScale = scale;
        const newScale = p.constrain(
          scale - event.delta * zoomSensitivity,
          0.1,
          5
        );

        const mouseXCanvas = p.mouseX - currentOffsetX;
        const mouseYCanvas = p.mouseY - currentOffsetY;

        const mouseXScaled = mouseXCanvas / oldScale;
        const mouseYScaled = mouseYCanvas / oldScale;

        targetOffsetX = p.mouseX - mouseXScaled * newScale;
        targetOffsetY = p.mouseY - mouseYScaled * newScale;

        scale = newScale;
        p.loop();
        event.preventDefault(); // 阻止默认滚动行为
        return false;
      }
    };

    function handleMousePressed() {
      isDragging = true;
      lastMouseX = p.mouseX;
      lastMouseY = p.mouseY;
    }

    function handleMouseDragged() {
      if (isDragging) {
        const dx = p.mouseX - lastMouseX;
        const dy = p.mouseY - lastMouseY;
        targetOffsetX += dx * 0.75;
        targetOffsetY += dy * 0.75;
        lastMouseX = p.mouseX;
        lastMouseY = p.mouseY;
        p.loop();
      }
    }

    function handleMouseReleased() {
      isDragging = false;
      p.loop();
    }
  };

  p5InstanceRef.value = new p5(sketch, sketchRef.value);
};

onMounted(() => {
  createP5Instance();
});

onUnmounted(() => {
  if (p5InstanceRef.value) {
    p5InstanceRef.value.remove();
  }
});

watch(
  () => [
    props.treeData,
    props.layoutType,
    props.colorScheme,
    props.canvasHeight,
    props.canvasWidth,
  ],
  () => {
    createP5Instance();
  }
);

defineExpose({ saveEntireMindMap, createP5Instance });

function saveEntireMindMapHelper(p, layoutManager, treeData) {
  const layoutResult = layoutManager.processLayout(
    treeData,
    props.canvasWidth,
    props.canvasHeight
  );
  const { left, right, top, bottom } = layoutResult.centerBoundingBox;

  const width = right - left;
  const height = bottom - top;

  const scaleFactor = Math.min(4000 / width, 4000 / height);

  const offScreenCanvas = p.createGraphics(
    Math.ceil(width * scaleFactor + 150),
    Math.ceil(height * scaleFactor + 150)
  );

  // offScreenCanvas.background(LightTheme);
  offScreenCanvas.background(props.theme === "light" ? LightTheme : DarkTheme);
  offScreenCanvas.push();
  offScreenCanvas.scale(scaleFactor);
  offScreenCanvas.translate(-left + 25, -top + 25);

  const drawNode = new DrawNode(offScreenCanvas, layoutManager.layoutType);
  const drawLine = new DrawLine(offScreenCanvas, layoutManager.layoutType);

  function drawTree(node) {
    const nodeCoords = drawNode.draw(node);
    if (node.children) {
      node.children.forEach((child) => {
        const childCoords = drawNode.draw(child);
        drawLine.draw(nodeCoords, childCoords, node.color, child.color);
        drawTree(child);
      });
    }
  }

  drawTree(layoutResult.centerResult);
  // 生成文件名
  let fileName = "mindmap.png";
  if (treeData && treeData.content) {
    const safeContent = treeData.content
      .replace(/[^a-z0-9]/gi, "_")
      .toLowerCase();
    fileName = `${safeContent}.png`;
  }

  offScreenCanvas.pixelDensity([2000]);
  offScreenCanvas.pop();
  offScreenCanvas.save(fileName);
  offScreenCanvas.remove();
  p.redraw();
}
</script>
