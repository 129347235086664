<template>
  <div class="flex flex-1 h-screen">
    <div
      class="flex flex-col items-start justify-center flex-1 px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24 dark:bg-gray-900"
    >
      <div class="w-full max-w-sm mx-auto lg:w-96">
        <div>
          <div class="flex items-center space-x-2">
            <svg
              class="w-8 h-8"
              style="color: #6366f1"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M13 10V3L4 14h7v7l9-11h-7z"
              ></path>
            </svg>
            <span class="text-xl font-bold" style="color: #6366f1"
              >PDF2MindMap</span
            >
          </div>
          <h2
            class="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900 dark:text-white"
          >
            {{ $t("login.title") }}
          </h2>
          <p class="mt-2 text-sm leading-6 text-gray-500 dark:text-white">
            {{ $t("login.tips") }}
            {{ " " }}
            <a
              href="/register"
              class="font-semibold text-indigo-600 hover:text-indigo-500"
              >{{ $t("login.signUp") }}</a
            >
          </p>
        </div>

        <div class="mt-10">
          <div>
            <form @submit.prevent="login" class="space-y-6">
              <div>
                <label
                  for="email"
                  class="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                  >{{ $t("login.email") }}</label
                >
                <div class="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autocomplete="email"
                    v-model="email"
                    required=""
                    class="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset dark:text-white dark:bg-gray-700 dark:ring-gray-500 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <label
                  for="password"
                  class="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                  >{{ $t("login.password") }}</label
                >
                <div class="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    v-model="password"
                    autocomplete="current-password"
                    required=""
                    class="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset dark:text-white dark:bg-gray-700 dark:ring-gray-500 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div class="flex items-center justify-between">
                <div class="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    class="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-600"
                  />
                  <label
                    for="remember-me"
                    class="block ml-3 text-sm leading-6 text-gray-700 dark:text-white"
                    >{{ $t("login.remember") }}</label
                  >
                </div>

                <div class="text-sm leading-6">
                  <a
                    href="#"
                    class="font-semibold text-indigo-600 hover:text-indigo-500"
                    >{{ $t("login.forget") }}</a
                  >
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  class="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  {{ $t("login.signIn") }}
                </button>
              </div>
            </form>
          </div>

          <div class="mt-10">
            <div class="relative">
              <div
                class="absolute inset-0 flex items-center"
                aria-hidden="true"
              >
                <div class="w-full border-t border-gray-200" />
              </div>
              <div
                class="relative flex justify-center text-sm font-medium leading-6"
              >
                <span
                  class="px-6 text-gray-900 bg-white dark:text-white dark:bg-gray-900"
                  >{{ $t("login.continue") }}</span
                >
              </div>
            </div>

            <div class="grid grid-cols-1 gap-4 mt-6">
              <div
                @click="signinGooglePopup"
                class="flex items-center justify-center w-full gap-3 px-3 py-2 text-sm font-semibold text-gray-900 bg-white rounded-md shadow-sm dark:bg-gray-800 dark:text-white dark:hover:bg-gray-700 ring-1 ring-inset ring-gray-300 dark:ring-gray-700 hover:bg-gray-50 focus-visible:ring-transparent"
              >
                <svg class="w-5 h-5" aria-hidden="true" viewBox="0 0 24 24">
                  <path
                    d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
                    fill="#EA4335"
                  />
                  <path
                    d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
                    fill="#4285F4"
                  />
                  <path
                    d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
                    fill="#FBBC05"
                  />
                  <path
                    d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
                    fill="#34A853"
                  />
                </svg>
                <span class="text-sm font-semibold leading-6">Google</span>
              </div>

              <!-- <a
                href="#"
                class="flex items-center justify-center w-full gap-3 px-3 py-2 text-sm font-semibold text-gray-900 bg-white rounded-md shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent"
              >
                <svg
                  class="h-5 w-5 fill-[#24292F]"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span class="text-sm font-semibold leading-6">GitHub</span>
              </a> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="relative flex-1 hidden w-0 lg:block dark:bg-gray-800">
      <img
        class="absolute inset-0 hidden object-cover w-full h-full dark:!block"
        src="/background.svg"
        alt=""
      />

      <img
        class="absolute inset-0 object-cover w-full h-full dark:hidden"
        src="/background-auth.jpg"
        alt=""
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import {
  signInWithPopup,
  signInWithEmailAndPassword,
  getAuth,
} from "firebase/auth";
import { useRouter } from "vue-router";
import { useFirebaseAuth } from "vuefire";
import { GoogleAuthProvider } from "firebase/auth";
const googleAuthProvider = new GoogleAuthProvider();

let router = useRouter();
let email = ref("");
let password = ref("");

const auth = getAuth();

console.log(auth.currentUser);
function login() {
  signInWithEmailAndPassword(auth, email.value, password.value)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
      console.log(user);
      router.push("/dashboard");

      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
    });
}

function signinGooglePopup() {
  signInWithPopup(auth, googleAuthProvider)
    .then((result) => {
      const user = result.user;
      console.log({ user });
      router.push("/dashboard");
      // ...
    })
    .catch((reason) => {
      console.error("Failed sign", reason);
    });
}
</script>
