import { MAX_WIDTH, LINE_HEIGHT, MARGIN, NODE_RADIUS } from './config';

class DrawNode {
  constructor(p, layoutType) {
    this.p = p;
    this.layoutType = layoutType;
  }

  draw(node) {
    const [x, y, width, height, position] = [
      node.x,
      node.y,
      node.width,
      node.height,
      node.position,
    ];

    // 定义圆角半径
    const cornerRadius = NODE_RADIUS; // 圆角程度

    // 绘制圆角矩形
    this.p.push();
    this.p.stroke(node.color.border); // 边框颜色
    this.p.strokeWeight(1); // 边框宽度
    this.p.fill(node.color.background); // 填充颜色
    this.p.rect(x, y, width, height, cornerRadius);
    this.p.pop();

    // 绘制节点文本
    this.p.push();
    this.p.fill(node.color.text);
    this.p.noStroke();
    this.p.textAlign(this.p.LEFT, this.p.TOP);
    this.p.textSize(12);

    const words = node.content.toString().split(' ');
    let line = '';
    let lines = [];
    let currentLineWidth = 0;

    for (let i = 0; i < words.length; i++) {
      const word = words[i];
      const wordWidth = this.p.textWidth(word + ' ');
      if (currentLineWidth + wordWidth > MAX_WIDTH) {
        lines.push(line.trim());
        line = word + ' ';
        currentLineWidth = wordWidth;
      } else {
        line += word + ' ';
        currentLineWidth += wordWidth;
      }
    }
    lines.push(line.trim());

    const totalTextHeight = lines.length * LINE_HEIGHT;
    let startY = y + (height - totalTextHeight) / 2 + MARGIN; // Center vertically

    lines.forEach((line, index) => {
      this.p.text(line, x + MARGIN, startY + index * LINE_HEIGHT); // 增加左边距到 10
    });

    this.p.pop();

    return { x, y, width, height, position };
  }
}

export default DrawNode;