<template>
  <div class="flex items-center h-screen px-6 py-24 bg-white sm:py-32 lg:px-8">
    <div class="max-w-2xl mx-auto text-center">
      <h2
        class="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl"
      >
        Confirm Email
      </h2>
      <p class="mt-6 text-lg leading-8 text-gray-600">
        Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem
        cupidatat commodo. Elit sunt amet fugiat veniam occaecat fugiat
        aliqua.{{ user?.email }}
      </p>
      <p
        class="text-base font-semibold leading-7 text-indigo-600"
        v-if="status == 0"
      >
        E-mail sended, please check your inbox
      </p>
      <div
        class="flex items-center justify-center text-base font-semibold leading-7 text-green-600"
        v-else-if="status == 1"
      >
        <!-- <CheckIcon class="w-6 h-6 text-green-600" aria-hidden="true" /> -->
        <div
          class="flex items-center justify-center w-12 h-12 mr-2 bg-green-100 rounded-full"
        >
          <CheckIcon class="w-6 h-6 text-green-600" aria-hidden="true" />
        </div>
        E-mail verified
      </div>
      <div
        class="flex items-center justify-center text-base font-semibold leading-7 text-grey-600"
        v-else-if="status == 2"
      >
        not login yet
      </div>
    </div>
  </div>
</template>
<script setup>
import { CheckIcon, XMarkIcon } from "@heroicons/vue/24/outline";
import { ref, onMounted } from "vue";
// import { useCurrentUser } from 'vuefire'
import { getAuth, sendEmailVerification } from "firebase/auth";
// const user = useCurrentUser()
// console.log(user)
var user = ref({
  email: "",
});
let status = ref(0);
onMounted(() => {
  const auth = getAuth();
  console.log(auth.currentUser);
  user.value = auth.currentUser;
  console.log(user.value);
  if (auth.currentUser == null) {
    status.value = 2;
    return 
  }
  if (!auth.currentUser.emailVerified) {
    sendEmailVerification(auth.currentUser).then(() => {
      status.value = 0;
    });
  } else {
    status.value = 1;
    console.log("already verified");
  }
});
</script>
